import { EVENT_TYPES } from "app/constants";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { coinPropType } from "types/coin";

import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
  DialogApplyButton,
  DialogLogo,
  DialogTotal,
  HealthFactorProgressBar,
  NumericTextField,
  Spinner,
} from "components";

import { useBorrowContext } from "context/contracts/BorrowContext";
import { constants } from "ethers";
import { useWallet } from "hooks";
import { useDebounce } from "hooks/common/useDebounce";
import useDecimalToken from "hooks/contexts/BorrowContext/useDecimalToken";
import { useCoinMutations } from "hooks/mutation";
import { useGetAvailableToWithdraw } from "hooks/pit/useGetAvailableToWithdraw";
import get from "lodash/get";
import { formatUnits, parseUnits } from "utils/number";
import { calculateSafetyBuffer, convertNumberHex } from "utils/utils";
import { TokenType } from "types/token";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    minWidth: 400,
    color: theme.palette.primary.main,
    paddingLeft: 0,
    paddingRight: 0,
    width: 429,

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      minWidth: "100%",
      height: "100%",
      width: "100%",
    },
  },
  dialogStyled: {
    width: "100%",
    backgroundColor: "#333333",
    padding: "10px 0px",
  },
  contentInner: {
    position: "relative",
    backgroundColor: "#F8F8F8",
    [theme.breakpoints.down("sm")]: {
      flex: 1,
    },
  },
  dialogTotal: {
    padding: "0px !important",
  },
  withdrawInfo: {
    [theme.breakpoints.down("xs")]: {
      "& p, & span": {
        fontSize: 14,
      },
    },
  },
}));

const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

const WithdrawModal = ({ data, onClose }) => {
  const {
    name,
    logo,
    // allowance,
    address: prjTokenAddress,
    symbol,
    prjAmount,
    healthFactor,
    lendingToken,
    data: projectTokenInfo,
    underlyingTokens,
  } = data;

  const ctx = useBorrowContext();
  const tokenDecimal = useDecimalToken();

  const isFetching = useMemo(() => get(ctx, ["isFetching"], false), [ctx]);
  const refetch = useMemo(() => get(ctx, ["refetch"], () => {}), [ctx]);

  const decimalToken = useMemo(
    () => get(ctx, ["decimalOfContractToken", prjTokenAddress], 0),
    [ctx, prjTokenAddress]
  );

  const classes = useStyles();
  const { connected } = useWallet();
  const [inputNum, setInputValue] = useState("");
  const inputValue = useDebounce(inputNum, 200);
  const [safetyBuffer, setSafetyBuffer] = useState(1 - 1 / healthFactor);

  const { isLoading, withdraw } = useCoinMutations({
    projectTokenAddress: prjTokenAddress,
    name,
    amount: inputValue,
    kind: EVENT_TYPES.withdraw,
  });

  const { data: availableToWithdraw, isLoading: availableToWithdrawFetching } =
    useGetAvailableToWithdraw(prjTokenAddress, lendingToken ? lendingToken.address : ZERO_ADDRESS);

  const maxValue = useMemo(
    () =>
      availableToWithdraw ? formatUnits(availableToWithdraw, tokenDecimal[prjTokenAddress]) : 0,
    [availableToWithdraw, tokenDecimal, prjTokenAddress]
  );

  const isDisabled =
    !connected ||
    !inputValue.toString().length ||
    !Number(inputValue) ||
    Number(inputValue) > Number(maxValue);

  const resetInputValue = () => {
    setInputValue("");
  };

  const isWithdrawAll = useMemo(
    () => Math.abs(+inputValue - maxValue) < 1e-5,
    [inputValue, maxValue]
  );

  const handleWithdraw = async () => {
    const paramWithdraw = {
      value: isWithdrawAll
        ? constants.MaxUint256.toString()
        : parseUnits(convertNumberHex(inputValue), decimalToken),
      _projectTokenAddress: prjTokenAddress,
      lendingAddress: lendingToken ? lendingToken.address : ZERO_ADDRESS,
    };

    await withdraw(paramWithdraw);
    await refetch();
    onClose();
    resetInputValue();
  };

  useEffect(() => {
    if (inputValue && lendingToken) {
      const lvrDenominator =
        Number(projectTokenInfo.pitCollateral.decimal) -
        Number(inputValue) *
          Number(projectTokenInfo.lvr.decimal) *
          Number(lendingToken.lvr) *
          Number(projectTokenInfo.price);
      const lvrNumerator =
        Number(projectTokenInfo.totalOutstanding.decimal) * Number(lendingToken.price);

      if (lvrNumerator > lvrDenominator) setSafetyBuffer(0);
      else setSafetyBuffer(1 - lvrNumerator / lvrDenominator);
    } else if (!lendingToken) {
      setSafetyBuffer(1);
    } else {
      const newSafetyBuffer = calculateSafetyBuffer(healthFactor);
      setSafetyBuffer(newSafetyBuffer);
    }
  }, [healthFactor, inputValue, projectTokenInfo, lendingToken]);

  const isLoadingModal = useMemo(
    () => isLoading || isFetching || availableToWithdrawFetching,
    [availableToWithdrawFetching, isFetching, isLoading]
  );

  return (
    <>
      <DialogLogo logoUrl={logo} name={name} underlyingTokens={underlyingTokens} />

      {isLoadingModal && <Spinner position="absolute" color="success" />}

      <Box pt={5} p={0} className={classes.rootContainer}>
        <NumericTextField
          value={inputNum}
          onChange={setInputValue}
          maxValue={maxValue}
          addressToken={prjTokenAddress}
          decimalToken={decimalToken}
          tokenType={TokenType.COLLATERAL}
        />

        {/* {!!allowance && ( */}
        <Box px={2} py={2} mt={2} className={classes.contentInner}>
          <HealthFactorProgressBar value={safetyBuffer} isSafetyBuffer />
        </Box>
        {/* )} */}

        <Box className={classes.withdrawInfo}>
          {Number(maxValue) > 0 ? (
            <DialogApplyButton disabled={isDisabled} onClick={handleWithdraw}>
              Withdraw
            </DialogApplyButton>
          ) : (
            <DialogApplyButton disabled>No balance to withdraw</DialogApplyButton>
          )}
          <Box py={2} className={classes.dialogStyled}>
            <DialogTotal
              title="Available To Withdraw"
              value={maxValue}
              currency={symbol}
              type="withdraw"
            />
            <DialogTotal
              title="Currently Supplying"
              value={prjAmount}
              currency={symbol}
              type="withdraw"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

WithdrawModal.propTypes = {
  data: coinPropType.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default WithdrawModal;
