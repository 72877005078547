import { formatUnits } from "utils/number";
import { get as _get } from "lodash";

export const handleGetLiquidationThreshold = (liquidationThresholdRaw = []) => {
  if (!liquidationThresholdRaw?.length) return [];
  const liquidationThresholdFinals = liquidationThresholdRaw.reduce((results, o) => {
    const lendingToken = _get(o, ["methodParameters", 2]);
    const collateralToken = _get(o, ["methodParameters", 1]);
    const symbolUnit = _get(o, ["reference"]);
    const value = formatUnits(_get(o, ["returnValues", 0, "hex"], 0), 0);

    return [...results, { value, collateralToken, lendingToken, symbolUnit }];
  }, []);

  return liquidationThresholdFinals;
};
