import axios from "axios";

const baseURL = "https://apiv5.paraswap.io";

export const getPriceParaswap = async (
  { chainId = 1 },
  tokenCollateral,
  lendingToken,
  amountTokenBN,
  srcDecimals = 6,
  destDecimals = 6,
  side = "SELL"
) => {
  const { data: priceData } = await axios.get(`${baseURL}/prices/`, {
    params: {
      srcToken: tokenCollateral,
      amount: amountTokenBN.toString(),
      destToken: lendingToken,
      side,
      network: +chainId,
      srcDecimals,
      destDecimals,
      maxImpact: 100,
    },
  });

  return { ...priceData };
};
