import { Contract } from "ethers";
import useWallet from "hooks/useWallet";
import React, { useMemo } from "react";

import { getInstantLeverageZkSyncContract } from "utils/ethereum/contracts";

export const useInstantLeverageContract = () => {
  const { chainId, provider } = useWallet();

  const leverageInstance = useMemo(() => getInstantLeverageZkSyncContract(chainId), [chainId]);

  const contract = React.useMemo(() => {
    const smc = new Contract(leverageInstance.address, leverageInstance.abi, provider);

    return smc;
  }, [leverageInstance, provider]);

  return { leverageContract: contract, leverageInstance };
};
