import { get } from "lodash";

export function handleGetPITRemainingAmount(pitBalanceList) {
  const result = {};
  pitBalanceList.forEach((o) => {
    const key = get(o, ["methodParameters", 1]);
    if (!result[key]) {
      result[key] = {};
    }
    const borrowed = get(o, ["methodParameters", 2]);
    const value = get(o, ["returnValues", 0, "_hex"], 0);

    result[key][borrowed] = value;
  });
  return result;
}
