"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadPairInstance = void 0;
const MuteSwitchPairDynamic_1 = require("../abis/MuteSwitchPairDynamic");
const UniswapV2Pair_1 = require("../abis/UniswapV2Pair");
const pairType_1 = require("../enum/pairType");
const loadContract_1 = require("./loadContract");
const loadPairInstance = (pair, type, signerOrProvider) => {
    if (type === pairType_1.Pair.Mute) {
        return (0, loadContract_1.loadContractInstance)(pair, MuteSwitchPairDynamic_1.MuteSwitchPairDynamic_ABI, signerOrProvider);
    }
    return (0, loadContract_1.loadContractInstance)(pair, UniswapV2Pair_1.UniswapV2Pair_ABI, signerOrProvider);
};
exports.loadPairInstance = loadPairInstance;
