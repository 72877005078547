import useWallet from "hooks/useWallet";
import { useCallback, useMemo } from "react";
import { getPrimaryLendingPlatformWrappedTokenGateway } from "utils/ethereum/contracts";
import { useContract } from "wagmi";
import {
  getDataToUpdatePrice,
  getTokensNeedToUpdatePrice,
} from "utils/ethereum/getDataToUpdatePrice";
import { ZERO_ADDRESS, isEqualLowerString } from "utils/network";
import { BigNumber } from "ethers";
import { wrapNativeToken } from "utils/token";
import { getGasLimit } from "utils/utils";
import { usePriceContract } from "./core/usePriceContract";

export const usePITWrappedTokenGatewayContract = () => {
  const { chainId, signer } = useWallet();
  const GatewayInstance = useMemo(
    () => getPrimaryLendingPlatformWrappedTokenGateway(chainId),
    [chainId]
  );

  const { PriceContract } = usePriceContract();

  const contract = useContract({
    address: GatewayInstance?.address,
    abi: GatewayInstance?.abi,
    signerOrProvider: signer,
  });

  const depositCall = useCallback(
    async ({ collateralAmount }) => {
      if (!contract) return;
      const gasLimit = await getGasLimit(contract, "deposit", { value: collateralAmount });
      const callTx = await contract.deposit({ value: collateralAmount, gasLimit });

      await callTx.wait();
    },
    [contract]
  );

  const withdrawCall = useCallback(
    async ({ wrappedETHAddress, collateralAmount, lendingAddress }) => {
      if (!contract || !PriceContract) return;

      const noLending = isEqualLowerString(lendingAddress, ZERO_ADDRESS);

      const tokenToGetUpdatePrice = noLending
        ? [wrappedETHAddress]
        : await getTokensNeedToUpdatePrice(wrappedETHAddress, lendingAddress, false, PriceContract);

      const { priceIds, payableAmount, updateData } = await getDataToUpdatePrice(
        tokenToGetUpdatePrice,
        PriceContract
      );
      const gasLimit = await getGasLimit(
        contract,
        "withdraw",
        collateralAmount,
        priceIds,
        updateData,
        { value: payableAmount }
      );
      const callTx = await contract.withdraw(collateralAmount, priceIds, updateData, {
        value: payableAmount,
        gasLimit,
      });

      await callTx.wait();
    },
    [contract, PriceContract]
  );

  const supplyCall = useCallback(
    async ({ lendingTokenAmount }) => {
      if (!contract || !PriceContract) return;
      const { address: lendingTokenAddress } = wrapNativeToken(chainId);
      const tokenAddressNeedToUpdatePrice = await getTokensNeedToUpdatePrice(
        lendingTokenAddress,
        ZERO_ADDRESS,
        false,
        PriceContract
      );

      const { priceIds, payableAmount, updateData } = await getDataToUpdatePrice(
        tokenAddressNeedToUpdatePrice,
        PriceContract
      );
      const value = BigNumber.from(lendingTokenAmount).add(BigNumber.from(payableAmount));
      const gasLimit = await getGasLimit(
        contract,
        "supply",
        lendingTokenAmount,
        priceIds,
        updateData,
        payableAmount,
        { value }
      );
      const callTx = await contract.supply(
        lendingTokenAmount,
        priceIds,
        updateData,
        payableAmount,
        {
          value,
          gasLimit,
        }
      );

      await callTx.wait();
    },
    [PriceContract, chainId, contract]
  );

  const redeemUnderlyingCall = useCallback(
    async ({ lendingTokenAmount }) => {
      if (!contract || !PriceContract) return;
      const { address: lendingTokenAddress } = wrapNativeToken(chainId);
      const tokenAddressNeedToUpdatePrice = await getTokensNeedToUpdatePrice(
        lendingTokenAddress,
        ZERO_ADDRESS,
        false,
        PriceContract
      );

      const { priceIds, payableAmount, updateData } = await getDataToUpdatePrice(
        tokenAddressNeedToUpdatePrice,
        PriceContract
      );
      const gasLimit = await getGasLimit(
        contract,
        "redeemUnderlying",
        lendingTokenAmount,
        priceIds,
        updateData,
        { value: payableAmount }
      );
      const callTx = await contract.redeemUnderlying(lendingTokenAmount, priceIds, updateData, {
        value: payableAmount,
        gasLimit,
      });

      await callTx.wait();
    },
    [PriceContract, chainId, contract]
  );

  const borrowCall = useCallback(
    async ({ collateral, lendingAmount }) => {
      if (!contract || !PriceContract) return;
      const { address: lendingToken } = wrapNativeToken(chainId);
      const tokenAddressNeedToUpdatePrice = await getTokensNeedToUpdatePrice(
        collateral,
        lendingToken,
        true,
        PriceContract
      );
      const { priceIds, payableAmount, updateData } = await getDataToUpdatePrice(
        tokenAddressNeedToUpdatePrice,
        PriceContract
      );
      const gasLimit = await getGasLimit(
        contract,
        "borrow",
        collateral,
        lendingAmount,
        priceIds,
        updateData,
        { value: payableAmount }
      );
      const callTx = await contract.borrow(collateral, lendingAmount, priceIds, updateData, {
        value: payableAmount,
        gasLimit,
      });

      await callTx.wait();
    },
    [PriceContract, chainId, contract]
  );

  const repayCall = useCallback(
    async ({ collateral, lendingAmount }) => {
      if (!contract) return;
      const gasLimit = await getGasLimit(contract, "repay", collateral, lendingAmount, {
        value: lendingAmount,
      });
      const callTx = await contract.repay(collateral, lendingAmount, {
        value: lendingAmount,
        gasLimit,
      });

      await callTx.wait();
    },
    [contract]
  );

  const leveragedBorrowWithProjectETHCall = useCallback(
    async ({
      lendingInfo,
      notionalExposure,
      marginCollateralAmount,
      buyCalldata,
      leverageType,
      priceIds,
      updateData,
      updateFee,
      payableAmount,
    }) => {
      if (!contract) return;
      const gasLimit = await getGasLimit(
        contract,
        "leveragedBorrowWithProjectETH",
        lendingInfo,
        notionalExposure,
        marginCollateralAmount,
        buyCalldata,
        leverageType,
        priceIds,
        updateData,
        updateFee,
        { value: payableAmount }
      );
      const callTx = await contract.leveragedBorrowWithProjectETH(
        lendingInfo,
        notionalExposure,
        marginCollateralAmount,
        buyCalldata,
        leverageType,
        priceIds,
        updateData,
        updateFee,
        { value: payableAmount, gasLimit }
      );

      await callTx.wait();
    },
    [contract]
  );

  return {
    callback: {
      depositCall,
      withdrawCall,
      supplyCall,
      redeemUnderlyingCall,
      borrowCall,
      leveragedBorrowWithProjectETHCall,
      repayCall,
    },
    data: {
      GatewayInstance,
    },
  };
};
