import axios from "axios";
import { formatUnits } from "utils/number";

const secondaryURL = process.env.REACT_APP_OPEN_OCEAN_API_URL;
const primaryURL = "https://open-api.openocean.finance/v3";
const basePath = "swap_quote";

export const getDataFromOpenOcean = async (
  inToken,
  outToken,
  chainId,
  amount,
  provider,
  account
) => {
  const gasPrice = await provider.getGasPrice();
  const gasPriceGWEI = formatUnits(gasPrice, "gwei");

  const params = {
    inTokenAddress: inToken,
    outTokenAddress: outToken,
    amount: amount.toString(),
    slippage: 5,
    gasPrice: gasPriceGWEI,
    account,
  };

  let response;

  try {
    response = await axios.get(`${primaryURL}/${+chainId}/${basePath}`, { params });
  } catch (error) {
    response = await axios.get(`${secondaryURL}/${+chainId}/${basePath}`, { params });
  }

  return response?.data;
};
