/* eslint-disable no-await-in-loop */
import { parseUnits } from "utils/number";
import { MainNetworkSupported } from "utils/network";
import { generateMulticallRequest } from "utils/contract/multicall";
import { PITContractMethod, UniswapV2FactoryMethod } from "constants/methodName";
import { ContractName } from "constants/contractName";
import { getDataToUpdatePrice } from "utils/ethereum/getDataToUpdatePrice";

// fetch batch with contraints group
export async function prepareRequestMulticallZksync({
  PITContract,
  listToken: collateralTokens,
  availableBorrowTokens: lendingTokenList,
  account,
  decimalTokens,
  PriceContract,
  chainId,
  UniswapV2FactoryContract,
}) {
  const usdcToken = lendingTokenList.find((o) => o.symbol === "USDC" || o.symbol === "USDC.e");
  const usdcAddress = usdcToken?.address || "";

  const listRequestToPITContract = [];
  const listRequestToUniswapV2FactoryContract = [];

  const tokenAddressList = Array.from(
    // reduce duplicate
    new Set([...collateralTokens, ...lendingTokenList].map((o) => o.address))
  );

  const { payableAmount, updateData, priceIds } = await getDataToUpdatePrice(
    tokenAddressList,
    PriceContract
  );

  collateralTokens.forEach((prjToken) => {
    listRequestToPITContract.push([
      generateMulticallRequest(
        PITContract,
        PITContractMethod.getTokenEvaluationWithUpdatePrices,
        [
          prjToken.address,
          parseUnits("1.0", decimalTokens[prjToken.address]),
          priceIds,
          updateData,
        ],
        prjToken.address,
        ContractName.PITContract,
        payableAmount
      ),
      generateMulticallRequest(
        PITContract,
        PITContractMethod.borrowLimitPerCollateral,
        [prjToken.address],
        prjToken.address,
        ContractName.PITContract
      ),
      generateMulticallRequest(
        PITContract,
        PITContractMethod.pitCollateral,
        [account, prjToken.address],
        prjToken.address,
        ContractName.PITContract
      ),
      generateMulticallRequest(
        PITContract,
        PITContractMethod.getLendingToken,
        [account, prjToken.address],
        prjToken.symbol,
        ContractName.PITContract
      ),
    ]);

    lendingTokenList.forEach((lendingToken) => {
      listRequestToPITContract.push([
        generateMulticallRequest(
          PITContract,
          PITContractMethod.getLendingAvailableToBorrowWithUpdatePrices,
          [account, prjToken.address, lendingToken.address, priceIds, updateData],
          lendingToken.symbol,
          ContractName.PITContract,
          payableAmount
        ),
        generateMulticallRequest(
          PITContract,
          PITContractMethod.getPosition,
          [account, prjToken.address, lendingToken.address],
          lendingToken.symbol,
          ContractName.PITContract
        ),
        generateMulticallRequest(
          PITContract,
          PITContractMethod.pitRemaining,
          [account, prjToken.address, lendingToken.address],
          lendingToken.symbol,
          ContractName.PITContract
        ),
      ]);
    });
  });

  lendingTokenList.forEach((lending) => {
    listRequestToPITContract.push([
      generateMulticallRequest(
        PITContract,
        PITContractMethod.getTokenEvaluationWithUpdatePrices,
        [lending.address, parseUnits("1.0", decimalTokens[lending.address]), priceIds, updateData],
        lending.address,
        ContractName.PITContract,
        payableAmount
      ),
      generateMulticallRequest(
        PITContract,
        PITContractMethod.borrowLimitPerLendingToken,
        [lending.address],
        lending.address,
        ContractName.PITContract
      ),
      generateMulticallRequest(
        PITContract,
        PITContractMethod.lendingTokenInfo,
        [lending.address],
        lending.address,
        ContractName.PITContract
      ),
      generateMulticallRequest(
        PITContract,
        PITContractMethod.getTotalBorrowPerLendingToken,
        [lending.address],
        lending.address,
        ContractName.PITContract
      ),
    ]);

    if (!MainNetworkSupported.includes(+chainId)) {
      listRequestToUniswapV2FactoryContract.push([
        generateMulticallRequest(
          UniswapV2FactoryContract,
          UniswapV2FactoryMethod.getPair,
          [lending.address, usdcAddress],
          usdcAddress,
          ContractName.UniswapV2FactoryContract
        ),
      ]);
    }
  });

  return [...listRequestToPITContract, ...listRequestToUniswapV2FactoryContract];
}
