/* eslint-disable no-unused-vars */
import { useFetchGraphData } from "hooks/query/graphQL/useFetchGraphData";
import React, { useCallback, useEffect, useRef } from "react";
import { useBlockNumber } from "wagmi";
import { EventContractContext } from "./EventContext";

export const useEventContractAction = () => React.useContext(EventContractContext);

export const EventContractProvider = ({ children }) => {
  const [eventName, setEventName] = React.useState();

  const { updateDataGraph } = useFetchGraphData();

  const blockNumber = useBlockNumber();
  // refresh by blockNumber
  useEffect(() => {
    updateDataGraph();
  }, [blockNumber, updateDataGraph]);

  useEffect(() => {
    updateDataGraph();
  }, [eventName, updateDataGraph]);

  // useEffect(() => {
  //   if (eventName) {
  //     reloadDataIntervalRef.current = setInterval(updateDataGraph, 5000);
  //     setEventName(null);
  //   }

  //   return () => {
  //     if (reloadDataIntervalRef.current) {
  //       clearInterval(reloadDataIntervalRef.current);
  //     }
  //   }
  // }, [eventName, updateDataGraph]);

  // useEffect(() => {
  //   if (reloadDataIntervalRef.current) {
  //     clearInterval(reloadDataIntervalRef.current);
  //   }
  // }, [borrowLogsRaw?.length]);

  const onEvent = useCallback((e) => setEventName(e), [setEventName]);

  return (
    <EventContractContext.Provider value={{ onEvent }}>{children}</EventContractContext.Provider>
  );
};
