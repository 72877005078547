import { Box, Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";
import numeral from "numeral";
import NumberFormat from "react-number-format";
import { numberToString } from "utils/number";

const CURRENCY = "$";

const NumericText = ({ precision, value, suffix, moneyValue }) => {
  const defaultNumberFormatProps = {
    displayType: "text",
    thousandSeparator: true,
    prefix: moneyValue ? CURRENCY : "",
    suffix: suffix === "" || suffix === "%" ? suffix : ` ${suffix}`,
  };
  const isCompactMoneyValue = moneyValue && moneyValue.compact;
  if (isCompactMoneyValue) {
    const formattedValue = new Intl.NumberFormat("en", {
      notation: "compact",
    }).format(value);

    return <NumberFormat {...defaultNumberFormatProps} value={formattedValue} isNumericString />;
  }

  const formatNumberToShow = (number, decimals = 5) => {
    const minNumber = 10 ** -decimals;
    const format = "0".repeat(decimals - 1);
    if (Math.abs(number) > 0 && Math.abs(number) < minNumber) {
      return `0.${format}1`;
    }
    return numeral(number).format(`0,0.[${format}]0`);
  };

  const isTooSmall = Math.abs(value) < 1 / 10 ** precision && Math.abs(value) > 0;

  if (isTooSmall) {
    return (
      <Tooltip
        title={
          <NumberFormat
            {...defaultNumberFormatProps}
            value={numberToString(value)}
            decimalScale={18}
          />
        }
        arrow
        placement="top"
      >
        <Box>
          {"<"}
          <NumberFormat
            {...defaultNumberFormatProps}
            value={formatNumberToShow(value, precision)}
            fixedDecimalScale
          />
        </Box>
      </Tooltip>
    );
  }

  return (
    <NumberFormat
      {...defaultNumberFormatProps}
      value={value}
      decimalScale={precision}
      fixedDecimalScale
    />
  );
};

NumericText.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  precision: PropTypes.number,
  suffix: PropTypes.string,
  moneyValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      compact: PropTypes.bool,
    }),
  ]),
};

NumericText.defaultProps = {
  value: "",
  precision: 2,
  suffix: "",
  moneyValue: false,
};

export default NumericText;
