import { ERC20TokenMethod } from "constants/methodName";
import { ethers, BigNumber } from "ethers";
import { groupBy } from "lodash";

/**
 * Use to decode data from multicall in ZkSync
 * @param {Array} request -
 * - Array of request to aggregate multicall
 * - Array request must have methodName, contract, methodParameters, reference, contractName
 * @param {*} resultFromMulticall - Result returned from multicall after aggregate
 * @returns {Object} - Returns the pair key-value group by contractName
 */
export function decodeResultMulticallZkSync(request = [], resultFromMulticall) {
  const getDecodeResults = request.map((data, idx) => {
    const { methodName, contract, methodParameters, reference, contractName } = data;
    const resultAtIndex = resultFromMulticall[idx];

    let decodeValue;

    try {
      decodeValue = contract.interface.decodeFunctionResult(methodName, resultAtIndex);
    } catch (e) {
      if (methodName === ERC20TokenMethod.name || methodName === ERC20TokenMethod.symbol) {
        decodeValue = ethers.utils.parseBytes32String(resultAtIndex);
      } else {
        throw e;
      }
    }

    return {
      methodName,
      methodParameters,
      reference,
      returnValues: decodeValue,
      contractName,
      contract,
    };
  });

  const results = groupBy(getDecodeResults, "contractName");

  return results;
}

/**
 *
 * @param {*} resultFromMulticall
 * @returns {Array} - Return array of returnData from multicall
 */
export function formatMulticallResults(resultFromMulticall = []) {
  const returnData = [];
  resultFromMulticall.forEach((rs) => {
    returnData.push(rs.returnData);
  });

  return returnData;
}

export function calculateTotalValueInRequests(requests = []) {
  const valueOfRequest = requests.map((r) => r.value ?? BigNumber.from(0));
  const totalValue = valueOfRequest.reduce((pre, cur) => pre.add(cur), BigNumber.from(0));
  return totalValue;
}

function onMultiCallError(error, requests) {
  console.log("Multicall request fail", requests);
  throw error;
}

export async function callMultiCallRequests(
  requests = [],
  multiCallSMC,
  onError = onMultiCallError
) {
  const totalValue = calculateTotalValueInRequests(requests);
  const resultMulticall = await multiCallSMC.callStatic
    .aggregate3Value(requests, {
      value: totalValue,
    })
    .catch((error) => {
      if (onError) {
        onError(error, requests);
      }
    });
  const returnData = formatMulticallResults(resultMulticall);

  const results = decodeResultMulticallZkSync(requests, returnData);
  return results;
}

export function generateMulticallRequest(
  contract,
  methodName,
  params,
  reference,
  contractName,
  value = 0
) {
  return {
    target: contract.address,
    callData: contract.interface.encodeFunctionData(methodName, params),
    reference: reference ?? contract.address,
    methodName,
    methodParameters: params,
    contract,
    contractName: contractName ?? contract.address,
    value: value ? BigNumber.from(value) : BigNumber.from(0),
  };
}
