import { get } from "lodash";

export function handleLvrLending(lvrList) {
  const result = {};
  lvrList.forEach((o) => {
    const key = get(o, ["methodParameters", 0]);
    const numerator = +get(o, ["returnValues", 3, 0], 0);
    const denominator = +get(o, ["returnValues", 3, 1], 1);
    result[key] = numerator / denominator;
  });
  return result;
}
