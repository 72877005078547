/* eslint-disable react/jsx-key */
import { Fragment } from "react";
import PropTypes from "prop-types";
import { useTable, useExpanded } from "react-table";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  const tableCellFontSize = {
    "& *": {
      fontSize: 14,
    },
    [theme.breakpoints.down("sm")]: {
      "& *": {
        fontSize: 12,
      },
    },

    // [theme.breakpoints.down("xs")]: {
    //   "& *": {
    //     fontSize: 8,
    //   },
    // },
  };
  return {
    clickableArea: {
      cursor: "pointer",
      transition: theme.transitions.main,

      "&:hover": {
        backgroundColor: theme.palette.info.light,
        transition: theme.transitions.main,
      },
    },

    tableHead: {
      ...tableCellFontSize,
      border: "none",
    },

    headCell: {
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
    },

    tableCell: {
      ...tableCellFontSize,
      border: "none",
    },
  };
});

const Table = ({ columns, data, onRowClick, className, renderRowSubComponent }) => {
  const classes = useStyles();
  const { getTableProps, headerGroups, rows, prepareRow, getTableBodyProps, visibleColumns } =
    useTable(
      {
        columns,
        data: data || [],
      },
      useExpanded
    );
  const isRowClickEnable = typeof onRowClick === "function";

  const handleClick = (value) => () => {
    if (isRowClickEnable) onRowClick(value);
  };
  return (
    <TableContainer>
      <MuiTable {...getTableProps()} size="small" className={className}>
        <TableHead className={classes.tableHead}>
          {headerGroups?.map((headerGroup) => (
            <TableRow>
              {headerGroup.headers.map((column) => (
                <TableCell {...column.getHeaderProps(column.props)} className={classes.headCell}>
                  {column.render("Header")}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>

        <TableBody {...getTableBodyProps()}>
          {rows?.map((row) => {
            prepareRow(row);
            const rowProps = row.getRowProps();

            return (
              <Fragment key={rowProps.key}>
                <TableRow
                  {...rowProps}
                  className={clsx({
                    [classes.clickableArea]: isRowClickEnable,
                  })}
                  onClick={handleClick(row.original)}
                >
                  {row.cells.map((cell) => (
                    <TableCell
                      {...cell.getCellProps(cell.column.props)}
                      className={classes.tableCell}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  ))}
                </TableRow>
                {row.isExpanded && (
                  <tr>
                    <td colSpan={visibleColumns?.length}>{renderRowSubComponent({ row })}</td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

Table.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  onRowClick: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
  className: PropTypes.string,
};

Table.defaultProps = {
  onRowClick: null,
  className: "",
};

export default Table;
