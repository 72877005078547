import { primary, secondary, common, success } from "../palette";

const MuiButton = {
  props: {
    color: "secondary",
    variant: "contained",
    disableRipple: true,
    disableElevation: true,
  },
  overrides: {
    root: {
      padding: "10px 30px",
      borderRadius: 4,
    },

    outlinedSecondary: {
      border: `1px solid ${secondary.main}`,

      "&:hover": {
        backgroundColor: secondary.main,
      },

      "&$disabled": {
        border: "2px solid rgba(44, 39, 56, 0.24)",

        "& $label": {
          color: "rgba(44, 39, 56, 0.24)",
        },
      },
    },

    containedPrimary: {
      backgroundColor: success.main,

      "&:hover": {
        backgroundColor: success.dark,
      },
    },

    outlined: {
      padding: "10px 30px",

      "& $label": {
        color: common.white,
      },
    },

    label: {
      textTransform: "none",
    },

    text: {
      width: "100%",
      padding: "15px 0",
      backgroundColor: success.main,
      borderRadius: 0,
      fontSize: 18,
      color: common.white,

      "&:hover": {
        backgroundColor: success.light,
      },

      "&$disabled": {
        backgroundColor: "#BDBDBD",
        color: common.white,
      },

      "&$textPrimary": {
        backgroundColor: primary.main,
        color: common.white,

        "&:hover": {
          backgroundColor: primary.dark,
        },
      },
    },
  },
};

export default MuiButton;
