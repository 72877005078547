import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Button } from "@material-ui/core";
import { NavLink as Link } from "react-router-dom";
import NotFoundIcon from "assets/404.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    color: "#ffffff",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
  },
  icon: {
    marginTop: 20,
    marginBottom: 20,
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  title: {
    fontSize: "3rem",
    fontWeight: 700,
    color: "#68E1FD",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },
  message: {
    fontSize: "1rem",
    fontStyle: "italic",
    textAlign: "center",
  },
  button: {
    marginTop: 30,
    display: "flex",
    gap: 50,
    justifyContent: "center",
    width: "100%",
    "& button": {
      fontSize: "1.25rem",
      height: 50,
      boxShadow: "0px 0px 0px 10px #141414",
      "&:hover": {
        color: theme.palette.secondary.main,
        background: theme.palette.primary.light,
        boxShadow: "0px 0px 0px 10px #141414",
      },
    },
  },
}));

export default function Custom404() {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>TOKEN NOT FOUND</Typography>
      <Box className={classes.icon}>
        <img src={NotFoundIcon} alt="Not found" loading="lazy" />
      </Box>

      <Typography
        className={classes.message}
      >{`We apologize, but we couldn't locate the token you're accessing.`}</Typography>
      <Typography className={classes.message}>
        Please double-check and try again on another supported network.
      </Typography>
      <Box className={classes.button}>
        <Link to="/">
          <Button>Go Borrow</Button>
        </Link>
      </Box>
    </Box>
  );
}
