import { ApolloProvider } from "@apollo/client";
import { useModalState } from "hooks";
import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";
import { getClient } from "utils/client/graphql.client";
import { useNetwork } from "wagmi";
import WalletContext from "./WalletContext";

export const Status = {
  connecting: "connecting",
  connected: "connected",
  walletExist: "wallet-exist",
  disconnected: "disconnected",
};

const WalletProvider = ({ children }) => {
  const { chain } = useNetwork();

  const {
    isOpen: isWrongChain,
    onOpen: onSwitchChain,
    onClose: onCloseSwitchChain,
  } = useModalState();

  const chainId = Number(chain?.id || 0).toString(16);
  const { isOpen: isConnecting, onOpen: onConnecting, onClose: onCloseConnect } = useModalState();
  const client = useMemo(() => getClient(`0x${chainId}`), [chainId]);

  useEffect(() => {
    if (chain?.unsupported) onSwitchChain();
  }, [chain?.unsupported, onSwitchChain]);

  return (
    <WalletContext.Provider
      value={{
        isWrongChain,
        onSwitchChain,
        onCloseSwitchChain,
        isConnecting,
        onConnecting,
        onCloseConnect,
      }}
    >
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </WalletContext.Provider>
  );
};

WalletProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WalletProvider;
