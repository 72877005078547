import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { constants } from "ethers";
import { formatUnits, parseUnits, minBigNumber } from "utils/number";
import _get from "lodash/get";
import PropTypes from "prop-types";
import { useContext, useEffect, useMemo, useState } from "react";
import { BORROW_MAX_BUFFER, DECIMAL_SCALE, EVENT_TYPES } from "app/constants";
import {
  DialogApplyButton,
  DialogLogo,
  DialogTotal,
  HealthFactorProgressBar,
  NumericText,
  NumericTextField,
  Spinner,
} from "components";
import { useBorrowContext } from "context/contracts/BorrowContext";
import { useLendingTokenMutations } from "hooks/mutation";
import { useFetchGraphData } from "hooks/query/graphQL/useFetchGraphData";
import { coinPropType } from "types/coin";
import { convertNumberHex, calculateSafetyBuffer } from "utils/utils";
import useDecimalToken from "hooks/contexts/BorrowContext/useDecimalToken";
import { useDebounce } from "hooks/common/useDebounce";
import BigNumber from "bignumber.js";
import { isWrapNative } from "utils/token";
import { useWallet } from "hooks";
import { useERC20TokenApproval } from "hooks/contract/tokens/useERC20TokenApproval";
import { TokenType } from "types/token";
import { usePITWrappedTokenGatewayContract } from "hooks/contract/usePITWrappedTokenGatewayContract";
import { BorrowTokenContext } from "./SelectAssetModal";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    color: theme.palette.primary.main,
    paddingLeft: 0,
    paddingRight: 0,
    width: 429,

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      minWidth: "100%",
      height: "100%",
      width: "100%",
    },
  },

  contentInner: {
    position: "relative",
    backgroundColor: "#F8F8F8",
    [theme.breakpoints.down("sm")]: {
      flex: 1,
    },
  },
  supplyBox: {
    borderBottom: "1px solid #E0E0E0",
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),

    [theme.breakpoints.down("xs")]: {
      paddingBottom: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
  },
  supplyRates: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "18px",
  },
  borrowInfo: {
    [theme.breakpoints.down("xs")]: {
      "& p, & span": {
        fontSize: 14,
      },
    },
  },
}));

const BorrowModal = ({
  data: { name, healthFactor, address, data },
  lendingToken,
  lvrLending,
  onClose,
  openShare,
  token,
}) => {
  const { currentBorrowingLevel } = data;
  const classes = useStyles();
  const [inputNum, setInputValue] = useState("");
  const inputValue = useDebounce(inputNum, 200);
  const [safetyBuffer, setSafetyBuffer] = useState(1 - 1 / healthFactor);
  const ctx = useBorrowContext();
  const tokenDecimal = useDecimalToken();
  const usdcToken = localStorage.getItem("usdcToken");
  const [convertEstimatedPitRemaining, currentLimitLendingToken] = useMemo(
    () => [_get(ctx, ["pitRemaining"]), _get(ctx, ["currentLimitLendingToken"])],
    [ctx]
  );

  const { setShareData, cash } = useContext(BorrowTokenContext);
  const { chainId } = useWallet();
  const {
    data: { GatewayInstance },
  } = usePITWrappedTokenGatewayContract();

  const {
    approve: approveGateway,
    query: getAllowance,
    isLoading: gatewayLoading,
  } = useERC20TokenApproval(lendingToken.lendingToken, GatewayInstance?.address);

  const gatewayAllowance = useMemo(() => {
    if (!getAllowance.data) return 0;
    return getAllowance.data;
  }, [getAllowance.data]);

  const { APY } = useFetchGraphData();

  const isFetching = useMemo(() => _get(ctx, ["isFetching"], false), [ctx]);
  const refetch = useMemo(() => _get(ctx, ["refetch"], () => {}), [ctx]);

  const [borrowingApy] = useMemo(
    () => [
      [..._get(APY, "borrowing_apy", [])].find((o) => o.lendingTokenAddress === token?.address),
      [..._get(APY, "lender_apy", [])].find((o) => o.lendingTokenAddress === token?.address),
    ],

    [APY, token]
  );

  const remainingBorrowCap = useMemo(
    () =>
      new BigNumber(currentLimitLendingToken[lendingToken.lendingToken].limit)
        .dividedBy(lendingToken.price)
        .toString(),
    [currentLimitLendingToken, lendingToken]
  );

  const totalAvailableToBorrow = useMemo(
    () => _get(cash, [_get(token, "address"), "cash"], 0),
    [cash, token]
  );

  const totalAvailableToBorrowBN = useMemo(
    () => parseUnits(totalAvailableToBorrow, token?.decimal || 0),
    [token?.decimal, totalAvailableToBorrow]
  );

  const myMaxAvailable = useMemo(() => {
    const max = !token
      ? formatUnits(convertEstimatedPitRemaining[address][usdcToken], tokenDecimal[usdcToken])
      : formatUnits(
          convertEstimatedPitRemaining[address][token.address],
          tokenDecimal[token.address]
        );

    return max;
  }, [token, address, convertEstimatedPitRemaining, tokenDecimal, usdcToken]);

  const myMaxAvailableBN = useMemo(
    () => parseUnits(myMaxAvailable, token?.decimal),
    [myMaxAvailable, token?.decimal]
  );

  const getMin = useMemo(
    () => minBigNumber([myMaxAvailableBN, totalAvailableToBorrowBN]),
    [myMaxAvailableBN, totalAvailableToBorrowBN]
  );

  const maxValue = useMemo(
    () => formatUnits(getMin || 0, token?.decimal || 0),
    [getMin, token?.decimal]
  );

  useEffect(() => {
    if (inputValue) {
      const lvrDenominator =
        Number(data.totalOutstanding.decimal) === 0
          ? Number(data.pitCollateral.decimal) * lvrLending
          : Number(data.pitCollateral.decimal);

      const lvrNumerator = inputValue
        ? (Number(data.totalOutstanding.decimal) + Number(inputValue)) * Number(lendingToken.price)
        : Number(data.totalOutstanding.decimal) * Number(lendingToken.price);

      if (lvrNumerator > lvrDenominator) setSafetyBuffer(0);
      else setSafetyBuffer(1 - lvrNumerator / lvrDenominator);
    } else {
      const newSafetyBuffer = calculateSafetyBuffer(healthFactor);
      setSafetyBuffer(newSafetyBuffer);
    }
  }, [data, healthFactor, inputValue, lendingToken, lvrLending]);

  const { isLoading, borrow } = useLendingTokenMutations({
    name,
    amount: inputValue,
    kind: EVENT_TYPES.borrow,
  });

  const isDisabled = !inputValue || Number(inputValue) === 0 || +inputValue - 0.0001 > maxValue;
  const isFully = Math.abs(Number(inputValue) - Number(myMaxAvailable)) < 0.00000001;

  const isLoadingModal = useMemo(
    () => isLoading || isFetching || gatewayLoading,
    [gatewayLoading, isFetching, isLoading]
  );

  const resetInputValue = () => setInputValue("");
  const isNative = useMemo(
    () => isWrapNative(lendingToken.lendingToken, chainId),
    [chainId, lendingToken.lendingToken]
  );

  const needApproveToGateway = useMemo(() => {
    if (!isNative) return false;
    if (!gatewayAllowance) return true;
    return Number(formatUnits(gatewayAllowance, tokenDecimal[token.address])) < inputValue;
  }, [gatewayAllowance, inputValue, isNative, token.address, tokenDecimal]);

  const handleBorrow = async () => {
    const paramBorrowAsset = {
      prjAmount: isFully
        ? constants.MaxUint256.toString()
        : parseUnits(convertNumberHex(inputValue), _get(token, "decimal", 0)),
      prjAddress: address,
      lendingToken: _get(token, "address", constants.AddressZero),
    };
    await borrow(paramBorrowAsset);
    await refetch();

    setShareData({
      token: token.symbol,
      amount: inputValue,
      apy: borrowingApy?.amount || 0,
      colToken: name,
    });
    resetInputValue();
    onClose();
    openShare();
  };

  const handleApproveGateway = async () => {
    const value = parseUnits(convertNumberHex(inputValue), _get(token, "decimal", 0));
    await approveGateway({
      value: isFully
        ? new BigNumber(value.toHexString()).multipliedBy(1 + BORROW_MAX_BUFFER).toFixed(0)
        : value,
    });
    getAllowance.refetch();
  };

  return (
    <>
      <DialogLogo
        logoUrl={token?.logo || "./assets/coins_list/usd-coin.svg"}
        name={token?.name}
        underlyingTokens={token?.underlyingTokens}
      />

      {isLoadingModal && <Spinner position="absolute" color="success" />}

      <Box pt={5} p={0} className={classes.rootContainer}>
        <NumericTextField
          value={inputNum}
          onChange={setInputValue}
          maxValue={maxValue}
          decimalScale={DECIMAL_SCALE}
          addressToken={lendingToken.lendingToken}
          decimalToken={token?.decimal}
          tokenType={TokenType.LENDING}
        />

        <Box px={2} pb={2} mt={2} className={classes.contentInner}>
          <Box className={classes.supplyBox} style={{ paddingTop: "14px" }}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item md={6}>
                <Typography>Borrow APY</Typography>
              </Grid>
              <Grid item>
                <Typography color="primary">
                  <NumericText value={borrowingApy?.amount || 0} precision={2} suffix="%" />
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <HealthFactorProgressBar value={safetyBuffer} isSafetyBuffer />
        </Box>

        <Box className={classes.borrowInfo}>
          {needApproveToGateway ? (
            <DialogApplyButton disabled={isDisabled} onClick={handleApproveGateway}>
              Enable
            </DialogApplyButton>
          ) : (
            <DialogApplyButton disabled={isDisabled} onClick={handleBorrow}>
              Borrow
            </DialogApplyButton>
          )}

          <DialogTotal>
            <Grid container justifyContent="space-between">
              {currentBorrowingLevel && (
                <>
                  <Grid item>
                    <Typography color="secondary">Borrowing limit for this collateral</Typography>
                  </Grid>
                  <Grid item>
                    <Typography color="secondary">
                      <NumericText value={currentBorrowingLevel.decimal} moneyValue />
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </DialogTotal>
          <DialogTotal
            title="Remaining Borrow Cap"
            value={remainingBorrowCap}
            currency={token.symbol}
          />
          <DialogTotal
            title="Your Available Borrow"
            value={myMaxAvailable}
            currency={token.symbol}
          />
          <DialogTotal
            title="Total Available Borrow"
            value={totalAvailableToBorrow}
            currency={token.symbol}
          />
        </Box>
      </Box>
    </>
  );
};

BorrowModal.propTypes = {
  data: coinPropType.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default BorrowModal;
