import { BigNumber, Contract } from "ethers";
import { formatUnits } from "utils/number";
import { useWallet } from "hooks";
import { useMultiCallContract } from "hooks/contract/multicall/useMultiCallContract";
import { get, reduce } from "lodash";
import { useQuery } from "react-query";
import { ERC20TokenABI } from "utils/ethereum/abi";
import { generateMulticallRequest } from "utils/contract/multicall";
import { ERC20TokenMethod } from "constants/methodName";

const handleGetResult = (result) => {
  const final = reduce(
    result,
    (res, current) => {
      const decimalHex = get(current, [0, "returnValues", 0], "0");
      const tokenAddress = get(current, [0, "reference"], "");
      res[tokenAddress] = formatUnits(BigNumber.from(decimalHex), 0);
      return res;
    },
    {}
  );
  return final;
};

export const useDecimalToken = (tokens = []) => {
  const { chainId } = useWallet();
  const { callRequest } = useMultiCallContract();

  return useQuery(
    [
      "decimals",
      chainId,
      tokens
        .map((s) => s.address)
        .sort()
        .join(","),
    ],
    async () => {
      const requests = [];

      tokens.forEach((token) => {
        const TokenContract = new Contract(token.address, ERC20TokenABI);

        requests.push(
          generateMulticallRequest(
            TokenContract,
            ERC20TokenMethod.decimals,
            [],
            token.address,
            token.address
          )
        );
      });

      const results = await callRequest(requests);

      const decimals = handleGetResult(results);
      return decimals;
    },
    {
      retry: 3,
      enabled: !!tokens?.length,
    }
  );
};
