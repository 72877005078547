import { USD_DECIMAL } from "constants/contract";
import { constants } from "ethers";
import { formatUnits } from "utils/number";
import { get } from "lodash";
import { PITContractMethod } from "constants/methodName";

export function getLimitBorrowingOfLendingTokenFromMultiData(methodDataOnPitContract) {
  return get(methodDataOnPitContract, [PITContractMethod.borrowLimitPerLendingToken])?.reduce(
    (res, resultCall) => ({
      ...res,
      [get(resultCall, ["reference"], constants.AddressZero)]: formatUnits(
        get(resultCall, ["returnValues", 0], "0"),
        USD_DECIMAL
      ),
    }),
    {}
  );
}
