import useWallet from "hooks/useWallet";
import { useMemo } from "react";
import { useContract } from "wagmi";
import { getPriceProviderAggregatorZksyncContract } from "utils/ethereum/contracts";

export const usePriceContract = () => {
  const { chainId, provider } = useWallet();

  const PriceInstance = useMemo(() => getPriceProviderAggregatorZksyncContract(chainId), [chainId]);

  const contract = useContract({
    address: PriceInstance?.address,
    abi: PriceInstance?.abi,
    signerOrProvider: provider,
  });

  return { PriceContract: contract, PriceInstance };
};
