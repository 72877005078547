import BigNumber from "bignumber.js";
import { formatUnits } from "utils/number";
import { get } from "lodash";

export const handleGetHealthFactor = (positionData) =>
  [...positionData].reduce((res, curr) => {
    const numerator = get(curr, ["returnValues", 3, "_hex"], 0);
    const numeratorBN = new BigNumber(numerator);

    const denominator = get(curr, ["returnValues", 4], 0);
    const denominatorBN = +formatUnits(denominator, 0);

    if (denominatorBN === 0) return { ...res };

    const keyAddress = get(curr, ["methodParameters", 1], "0");
    return { ...res, [keyAddress]: numeratorBN.div(denominatorBN).toString() };
  }, {});
