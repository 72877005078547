import { useCallback, useMemo } from "react";
import { REACT_APP_MULTICALL } from "constants/NetworkChainId";
import { Contract } from "ethers";
import { MultiCallZkSyncABI } from "utils/ethereum/abi";
import { defaultProvider, defaultNetwork, ZERO_ADDRESS } from "utils/network";
import { callMultiCallRequests } from "utils/contract/multicall";
import { toHex } from "utils/number";
import useWallet from "../../useWallet";

export const useMultiCallContract = () => {
  const { chainId, provider, connected } = useWallet();

  const multiCallContract = useMemo(() => {
    if (!connected) {
      const multiCallAddress = REACT_APP_MULTICALL[toHex(defaultNetwork.id)];
      const multiCallSMC = new Contract(multiCallAddress, MultiCallZkSyncABI, defaultProvider);
      return multiCallSMC;
    }
    const multiCallAddress = REACT_APP_MULTICALL[chainId] || ZERO_ADDRESS;
    const multiCallSMC = new Contract(multiCallAddress, MultiCallZkSyncABI, provider);

    return multiCallSMC;
  }, [chainId, connected, provider]);

  const callRequest = useCallback(
    (requests) => callMultiCallRequests(requests, multiCallContract),
    [multiCallContract]
  );

  return {
    multiCallSMC: multiCallContract,
    callRequest,
  };
};
