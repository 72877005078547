/* eslint-disable no-await-in-loop */
import { Contract } from "ethers";
import { useWallet } from "hooks";
import { useMultiCallContract } from "hooks/contract/multicall/useMultiCallContract";
import { useLeveragePositions } from "hooks/contract/useLeverageContract";
import { get, groupBy, map, omit } from "lodash";
import { useQuery } from "react-query";
import { MainNetworkSupported, ZERO_ADDRESS } from "utils/network";
import { ERC20TokenABI } from "utils/ethereum/abi";
import { getUniswapV2Factory } from "utils/ethereum/contracts";
import { getDataToUpdatePrice } from "utils/ethereum/getDataToUpdatePrice";
import { formatUnits } from "utils/number";
import { generateMulticallRequest } from "utils/contract/multicall";
import { TokenType } from "types/token";
import { isWrapNative, wrapNativeToken } from "utils/token";
import {
  PITContractMethod,
  PriceContractMethod,
  ERC20TokenMethod,
  UniswapV2FactoryMethod,
} from "constants/methodName";
import { ContractName } from "constants/contractName";
import { usePITContractRead } from "hooks/contract/usePITContract";
import { usePriceContract } from "hooks/contract/core/usePriceContract";
import {
  getLvrFromResult,
  handleGetDepositTokens,
  handleGetPriceInUsd,
} from "./helper/getDataContract";

const localChainId = localStorage.getItem("chainId");

const getRequestMulticallZksync = async (
  availableBorrowTokens,
  listToken,
  account,
  provider,
  PriceContract,
  PITContract,
  UniswapV2FactoryInfo,
  chainId
) => {
  /* A list of requests to token contract. */
  const listRequestToTokenContracts = [];

  /* A list of requests to Price contract. */
  const listRequestToPriceContract = [];

  /* A list of requests to PIT contract. */
  const listRequestToPITContract = [];

  /* A list of requests to Uniswapv2Factory contract */
  const listRequestToUniswapV2FactoryContract = [];

  const UniswapV2FactoryContract = new Contract(
    UniswapV2FactoryInfo.address,
    UniswapV2FactoryInfo.abi,
    provider
  );

  const tokensNeedToUpdatePrice = Array.from(
    new Set([...availableBorrowTokens, ...listToken].map((token) => token.address))
  );
  const { priceIds, updateData, payableAmount } = await getDataToUpdatePrice(
    tokensNeedToUpdatePrice,
    PriceContract
  );

  for (let idx = 0; idx < availableBorrowTokens.length; idx += 1) {
    const token = availableBorrowTokens[idx];
    const erc20Contract = new Contract(token.address, ERC20TokenABI);

    listRequestToTokenContracts.push(
      generateMulticallRequest(
        erc20Contract,
        ERC20TokenMethod.allowance,
        [account, PITContract.address],
        ERC20TokenMethod.allowance,
        erc20Contract.address
      ),
      generateMulticallRequest(
        erc20Contract,
        ERC20TokenMethod.balanceOf,
        [account],
        ERC20TokenMethod.balanceOf,
        erc20Contract.address
      ),
      generateMulticallRequest(
        erc20Contract,
        ERC20TokenMethod.decimals,
        [],
        ERC20TokenMethod.decimals,
        erc20Contract.address
      )
    );
    listRequestToPriceContract.push(
      generateMulticallRequest(
        PriceContract,
        PriceContractMethod.getUpdatedPriceMethod,
        [token.address, priceIds, updateData],
        PriceContractMethod.getUpdatedPriceMethod,
        token.address,
        payableAmount
      )
    );
  }

  for (let idx = 0; idx < listToken.length; idx += 1) {
    const token = listToken[idx];
    const TokenContract = new Contract(token.address, ERC20TokenABI);

    listRequestToPITContract.push(
      generateMulticallRequest(
        PITContract,
        PITContractMethod.projectTokenInfo,
        [token.address],
        token.address,
        ContractName.PITContract
      ),
      generateMulticallRequest(
        PITContract,
        PITContractMethod.pitRemainingWithUpdatePrices,
        [account, token.address, ZERO_ADDRESS, priceIds, updateData],
        token.address,
        ContractName.PITContract,
        payableAmount
      )
    );

    listRequestToPriceContract.push(
      generateMulticallRequest(
        PriceContract,
        PriceContractMethod.getUpdatedPriceMethod,
        [token.address, priceIds, updateData],
        token.address,
        ContractName.PriceContract,
        payableAmount
      )
    );

    listRequestToTokenContracts.push(
      generateMulticallRequest(
        TokenContract,
        ERC20TokenMethod.allowance,
        [account, PITContract.address],
        ERC20TokenMethod.allowance,
        token.address
      ),
      generateMulticallRequest(
        TokenContract,
        ERC20TokenMethod.balanceOf,
        [account],
        ERC20TokenMethod.balanceOf,
        token.address
      ),
      generateMulticallRequest(
        TokenContract,
        ERC20TokenMethod.decimals,
        [],
        ERC20TokenMethod.decimals,
        token.address
      )
    );
  }

  if (!MainNetworkSupported.includes(+chainId)) {
    listToken.forEach((collateralToken) => {
      availableBorrowTokens.forEach((lendingToken) => {
        listRequestToUniswapV2FactoryContract.push(
          generateMulticallRequest(
            UniswapV2FactoryContract,
            UniswapV2FactoryMethod.getPair,
            [collateralToken.address, lendingToken.address],
            collateralToken.address,
            ContractName.UniswapV2FactoryContract
          )
        );
      });
    });
  }

  return [
    ...listRequestToTokenContracts,
    ...listRequestToPriceContract,
    ...listRequestToPITContract,
    ...listRequestToUniswapV2FactoryContract,
  ];
};

const useAvailableMultiCall = ({ loading, collaterals, availableBorrowTokens }) => {
  const { chainId, account, provider, fetchNativeBalance } = useWallet();

  const { callRequest } = useMultiCallContract();

  const isMainNet = MainNetworkSupported.includes(+chainId);

  const { getLeveragePosition } = useLeveragePositions([...collaterals].map((o) => o?.address));

  const { PriceContract } = usePriceContract();
  const {
    data: { PitContract },
  } = usePITContractRead();

  const collateralKeys = collaterals
    .map((c) => c.address)
    .sort()
    .join(",");
  return useQuery(
    ["available-multicall", account, collateralKeys, chainId],
    async () => {
      const { data: leveragePositionMap } = await getLeveragePosition();

      const listToken = [...collaterals];

      const UniswapV2FactoryContract = getUniswapV2Factory(chainId ?? localChainId);

      const requests = await getRequestMulticallZksync(
        availableBorrowTokens,
        listToken,
        account,
        provider,
        PriceContract,
        PitContract,
        UniswapV2FactoryContract,
        chainId
      );

      const results = {
        UniswapV2FactoryContract: [],
        ...(await callRequest(requests)),
      };

      const { name: nativeName, symbol: nativeSymbol, logo: nativeLogo } = wrapNativeToken(chainId);
      const nativeBalanceData = await fetchNativeBalance();
      const nativeBalance = nativeBalanceData?.data?.formatted;
      let userTokenInfo = [...availableBorrowTokens, ...listToken].map((token) => {
        const tokenContract = get(results, [token.address], []);

        const methodData = groupBy(tokenContract, "methodName");

        const balanceOf = get(methodData, [ERC20TokenMethod.balanceOf, 0, "returnValues", 0], "0");
        const decimal = get(methodData, [ERC20TokenMethod.decimals, 0, "returnValues", 0], "0");

        const isNative = isWrapNative(token.address, chainId);
        return {
          price: 0,
          ...token,
          allowance: !!get(methodData, [ERC20TokenMethod.allowance, 0, "returnValues"], false),
          balanceOf: isNative ? nativeBalance : formatUnits(balanceOf, decimal),
          name: isNative ? nativeName : token.name,
          symbol: isNative ? nativeSymbol : token.symbol,
          logo: isNative ? nativeLogo : token.logo,
          decimalNumber: formatUnits(decimal, 0),
        };
      });

      const availableDepositTokens = handleGetDepositTokens({
        results: { ...results },
        listToken,
        isMainNet,
      }).map((o) => {
        const isNative = isWrapNative(o.address, chainId);
        return {
          ...o,
          balance: isNative ? nativeBalance : o.balance,
          balanceInUsd: isNative ? nativeBalance * o.price : o.balanceInUsd,
          name: isNative ? nativeName : o.name,
          symbol: isNative ? nativeSymbol : o.symbol,
          logo: isNative ? nativeLogo : o.logo,
          isLeverage: leveragePositionMap?.get(o.address)?.isLeverage,
          leverageType: leveragePositionMap?.get(o.address)?.leverageType,
        };
      });

      const lvrByProjectTokens = listToken.reduce(
        (res, o) => ({
          ...res,
          [o.address]: getLvrFromResult(results, o.address),
        }),
        {}
      );

      const priceOfTokens = [...listToken, ...availableBorrowTokens].reduce((res, o) => {
        const { priceCollateralTokenBN, priceLendingTokenBN, priceDecimal } = handleGetPriceInUsd(
          results,
          o.address
        );

        return {
          ...res,
          [o.address]: {
            [TokenType.COLLATERAL]: formatUnits(priceCollateralTokenBN, priceDecimal),
            [TokenType.LENDING]: formatUnits(priceLendingTokenBN, priceDecimal),
          },
        };
      }, {});

      // inject price to userTokenInfo
      userTokenInfo = userTokenInfo.map((x) => ({
        ...x,
        price: priceOfTokens[x.address] || 0,
      }));

      const decimalOfContractToken = {};
      map(
        omit(results, [
          ContractName.PriceContract,
          isMainNet && ContractName.UniswapV2FactoryContract,
        ]),
        (obj) => {
          const key = get(obj, [0, "contract", "address"]).toLowerCase();

          const value = get(
            groupBy(obj, "methodName"),
            [ERC20TokenMethod.decimals, 0, "returnValues", 0],
            0
          );

          decimalOfContractToken[key] = +value;
        }
      );
      return {
        availableDepositTokens,
        userTokenInfo,
        decimalOfContractToken,
        lvrByProjectTokens,
        priceOfTokens,
      };
    },
    {
      retry: 3,
      enabled:
        !!PitContract &&
        !!PriceContract &&
        !loading &&
        !!account &&
        !!collaterals?.length &&
        !!availableBorrowTokens?.length &&
        !!chainId,
      placeholderData: {
        availableDepositTokens: availableBorrowTokens,
        userTokenInfo: collaterals,
        decimalOfContractToken: {},
        lvrByProjectTokens: {},
        priceOfTokens: {},
      },
    }
  );
};

export default useAvailableMultiCall;
