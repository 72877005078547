import { Contract } from "ethers";
import { getJumpRateModel } from "./contracts";

export const getRateInfo = async (bLendingToken, chainId, providerOrSigner) => {
  const contractInfo = getJumpRateModel(chainId);

  const JumpRateModelContract = new Contract(
    contractInfo.address,
    contractInfo.abi,
    providerOrSigner
  );

  const [lastInterestRate, lastAccrualBlockNumber, maxBorrowRate] =
    await JumpRateModelContract.rateInfo(bLendingToken);

  return {
    lastInterestRate,
    lastAccrualBlockNumber,
    maxBorrowRate,
  };
};
