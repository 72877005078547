import { BigNumber } from "ethers";
import { get, reduce } from "lodash";

export function handleGetIssuedPITAmount(
  pitCollateralList,
  pitRemainingList,
  totalOutstandingAssets
) {
  return reduce(
    pitCollateralList,
    (res, o) => {
      const key = get(o, ["methodParameters", 1]);
      const collateralValue = get(o, ["returnValues", 0], BigNumber.from(0));

      const remaining = pitRemainingList.find(
        (re) =>
          get(re, ["methodParameters", 1]) === key &&
          get(re, ["methodParameters", 2]) === totalOutstandingAssets[key]?.lendingToken
      );

      const getRemaining = get(remaining, ["returnValues", 0], BigNumber.from(0));
      const remainingValue = remaining ? getRemaining : 0;

      const value =
        totalOutstandingAssets[key] && Number(totalOutstandingAssets[key]?.value) > 0
          ? remainingValue
          : collateralValue;
      const currentPIT = get(res, [key], 0);

      if (+currentPIT > 0) return { ...res };

      const totals = get(res, "totals", 0);

      return {
        ...res,
        [key]: value,
        totals: BigNumber.from(totals).add(value),
      };
    },
    {
      totals: BigNumber.from(0),
    }
  );
}
