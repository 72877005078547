import { BigNumber, Contract, ethers } from "ethers";
import { formatUnits } from "utils/number";
import { useWallet } from "hooks";
import { useFetchGraphData } from "hooks/query/graphQL/useFetchGraphData";
import { useMultiCallContract } from "hooks/contract/multicall/useMultiCallContract";
import { get, reduce } from "lodash";
import { useQuery } from "react-query";
import { ERC20TokenABI, FTokenABI } from "utils/ethereum/abi";
import { generateMulticallRequest } from "utils/contract/multicall";
import { FTokenMethod, ERC20TokenMethod } from "constants/methodName";

const getCashDataFromResultMulticall = (result) => {
  let cashFToken = {};

  const final = reduce(
    result,
    (res, current, key) => {
      const raw = get(current, ["0", "returnValues"], "0");
      const methodName = get(current, ["0", "methodName"], "0");

      if (methodName === FTokenMethod.allowance)
        return {
          ...res,
          [key]: {
            allowance: ethers.BigNumber.from(raw[0]),
          },
        };

      const decimal = get(current, ["1", "returnValues", 0], "0");
      const lendingToken = get(current, ["1", "reference"], "0");

      const cashValue = BigNumber.from(raw[0]);

      cashFToken = {
        ...cashFToken,
        [lendingToken]: {
          cash: formatUnits(cashValue, decimal),
        },
      };
      return res;
    },
    {}
  );

  return { final, cashFToken };
};

export const useAllowanceToken = (fTokens = []) => {
  const { account, chainId } = useWallet();
  const { availableBorrowTokens = [] } = useFetchGraphData();
  const { callRequest } = useMultiCallContract();

  return useQuery(
    ["allowance", account, chainId, availableBorrowTokens.map((o) => o.address).toString()],
    async () => {
      const requests = [];

      if (account) {
        availableBorrowTokens.forEach((token) => {
          const erc20Contract = new Contract(token.address, ERC20TokenABI);
          requests.push(
            generateMulticallRequest(
              erc20Contract,
              ERC20TokenMethod.allowance,
              [account, fTokens.find((o) => o.token === token.address).ftoken],
              erc20Contract.address,
              erc20Contract.address
            )
          );
        });
      }

      fTokens.forEach((token) => {
        const FTokenContract = new Contract(token.ftoken, FTokenABI);
        const TokenContract = new Contract(token.token, ERC20TokenABI);

        requests.push(
          generateMulticallRequest(
            FTokenContract,
            FTokenMethod.getCash,
            [],
            FTokenContract.address,
            FTokenContract.address
          ),
          generateMulticallRequest(
            TokenContract,
            ERC20TokenMethod.decimals,
            [],
            token.token,
            FTokenContract.address
          )
        );
      });

      const results = await callRequest(requests);
      const { final: allowanceForFToken, cashFToken } = getCashDataFromResultMulticall(results);

      return {
        allowanceForFToken,
        cashFToken,
      };
    },
    {
      retry: 3,
      enabled: fTokens.length > 0 && availableBorrowTokens.length > 0,
    }
  );
};
