import { BorrowContextProvider } from "context/contracts/BorrowContextProvider";
import { useParams } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { OpenGraph } from "components/AssetLanding/OpenGraph";
import { useMemo } from "react";
import Custom404 from "components/AssetLanding/Custom404";
import { get } from "lodash";
import { wrapNativeToken, mappingTokenInfo } from "utils/token";
import { useAllTokens } from "hooks/contract/useTokenStats";
import AssetLandingPage from "./AssetLandingPage";

const AssetLanding = () => {
  const { symbol } = useParams();
  const { data: tokenInAllNetworks, isLoading } = useAllTokens();
  const tokens = useMemo(() => {
    const result = [];
    if (!tokenInAllNetworks || !tokenInAllNetworks.length) return [];
    tokenInAllNetworks.forEach((data) => {
      const projectTokens = get(data, "projectTokens", []);
      const lendingTokens = get(data, "lendingTokens", []);
      result.push({
        list: [...projectTokens, ...lendingTokens].map((token) =>
          mappingTokenInfo(token, `0x${Number(data.network.id).toString(16)}`)
        ),
        network: data.network,
      });
    });
    return result;
  }, [tokenInAllNetworks]);

  const formatSymbol = (token) => {
    if (token?.underlyingTokens?.length === 2) {
      return `${token.underlyingTokens[0].symbol}-${token.underlyingTokens[1].symbol}`;
    }
    return token?.symbol;
  };

  const coins = useMemo(() => {
    const selectedTokens = [];
    tokens.forEach((data) => {
      const { network } = data;
      const nativeCoin = wrapNativeToken(`0x${network.id.toString(16)}`);
      if (nativeCoin.symbol === symbol) {
        selectedTokens.push({
          ...nativeCoin,
          network,
        });
        return;
      }
      const token = data.list.find((t) => formatSymbol(t) === symbol);
      if (token) {
        selectedTokens.push({
          ...token,
          network,
        });
      }
    });
    return selectedTokens;
  }, [symbol, tokens]);

  const coinDescription = useMemo(() => {
    if (!coins.length) {
      return null;
    }
    const coin = coins[0];
    const description = `Borrow against, lend out and margin trade your ${coin.symbol} on Fringe Finance, the decentralized finance platform for small and large-cap assets.`;
    const lendingMarketLogo = coin?.logo;
    return {
      description,
      lendingMarketLogo,
      name: coin.name,
      symbol: coin.symbol,
      underlyingTokens: coin.underlyingTokens,
      coins,
    };
  }, [coins]);
  return coinDescription ? (
    <BorrowContextProvider>
      <MetaTags>
        <title>Lend out, borrow against and margin trade your {coinDescription.name}</title>
        <meta
          name="description"
          content="Optimize your capital by borrowing against or lending out your assets or using them to open margin trade positions on Fringe Finance, the decentralized lending, borrowing, and margin trade platform for assets of all kinds."
        />
        <OpenGraph
          siteName="Fringe"
          type="article"
          description={coinDescription.description}
          image={coinDescription.lendingMarketLogo}
          url={`https://fringe.fi/borrowing/${symbol}`}
          title={coinDescription.title}
        />
      </MetaTags>
      <AssetLandingPage
        name={coinDescription.name}
        symbol={coinDescription.symbol}
        underlying={coinDescription.underlyingTokens}
        coins={coins}
        description={coinDescription.description}
        lendingMarketLogo={coinDescription.lendingMarketLogo}
      />
    </BorrowContextProvider>
  ) : (
    !isLoading && <Custom404 />
  );
};

export default AssetLanding;
