import { Grid, InputAdornment, TextField, Tooltip, Typography } from "@material-ui/core";
import { ReactComponent as EditIcon } from "assets/svg/edit.svg";
import { ReactComponent as DoneIcon } from "assets/svg/done.svg";
import { ReactComponent as CancelIcon } from "assets/svg/reject.svg";
import { useEffect, useMemo, useState } from "react";
import { BigNumber } from "ethers";
import { formatUnits } from "utils/number";
import { useStyles } from "./useStyles";

const PriceDiffTextField = ({
  priceDiff,
  amountSwap,
  maxAmountSwap,
  onChange,
  symbol,
  decimals,
}) => {
  const classes = useStyles();
  const [isEditPriceDiff, setIsEditPriceDiff] = useState(false);
  const [customPriceDiff, setCustomPriceDiff] = useState(0);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (Number(priceDiff) > 0) {
      setCustomPriceDiff((priceDiff * 100).toFixed(2));
    }
  }, [priceDiff]);

  useEffect(() => {
    if (Number(priceDiff) === 0) {
      const amountSwapBN = BigNumber.from(amountSwap);
      const maxAmountSwapBN = BigNumber.from(maxAmountSwap);
      if (amountSwapBN.gt(0) && maxAmountSwapBN.gt(0)) {
        setCustomPriceDiff(((maxAmountSwapBN.sub(amountSwap) / amountSwapBN) * 100).toFixed(2));
      } else {
        setCustomPriceDiff("0.00");
      }
    }
  }, [amountSwap, maxAmountSwap, priceDiff]);

  const maxCollateralAmount = useMemo(() => {
    if (Number(customPriceDiff) > 0) {
      const max = BigNumber.from(amountSwap)
        .mul(Math.round((100 + Number(customPriceDiff)) * 10000))
        .div(1000000);
      return formatUnits(max, decimals);
    }
    return formatUnits(maxAmountSwap, decimals);
  }, [amountSwap, customPriceDiff, decimals, maxAmountSwap]);

  const checkCustomPriceDiff = () => {
    if (Number.isNaN(Number(customPriceDiff)) || Number(customPriceDiff) <= 0) {
      setError(true);
      setErrorMessage("Invalid value");
      return false;
    }
    setError(false);
    setErrorMessage("");
    return true;
  };

  const handleConfirm = () => {
    if (checkCustomPriceDiff()) {
      const response = onChange(customPriceDiff);
      if (!response.success) {
        setError(true);
        setErrorMessage(response.message);
        return false;
      }
      setIsEditPriceDiff(false);
      return true;
    }
    return false;
  };

  return (
    <>
      {!isEditPriceDiff ? (
        <Grid container className={classes.priceDiscrepancy} alignItems="center">
          {Number(customPriceDiff) !== 0 ? (
            <Tooltip
              title={`Maximum use ${Number(maxCollateralAmount).toFixed(
                6
              )} ${symbol} in your collateral to repay`}
              arrow
              placement="bottom"
            >
              <Typography color="primary">{customPriceDiff}% </Typography>
            </Tooltip>
          ) : (
            <Typography color="primary">{customPriceDiff}% </Typography>
          )}

          <EditIcon
            className={classes.actionIcon}
            onClick={() => setIsEditPriceDiff(true)}
            width={15}
            height={15}
          />
        </Grid>
      ) : (
        <Grid container className={classes.priceDiscrepancy} alignItems="center">
          <TextField
            defaultValue={customPriceDiff}
            error={error}
            helperText={errorMessage}
            onChange={(event) => setCustomPriceDiff(event.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            variant="standard"
          />
          <DoneIcon
            className={classes.actionIcon}
            onClick={() => handleConfirm()}
            width={15}
            height={15}
          />
          <CancelIcon
            className={classes.actionIcon}
            onClick={() => setIsEditPriceDiff(false)}
            width={15}
            height={15}
          />
        </Grid>
      )}
    </>
  );
};

export default PriceDiffTextField;
