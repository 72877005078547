import { Contract } from "ethers";
import React from "react";

import useWallet from "hooks/useWallet";
import { getPrimaryIndexTokenAtomicRepaymentZksync } from "utils/ethereum/contracts";

export const useAtomicRepaymentContractAction = () => {
  const { chainId, signer } = useWallet();

  const atomicRepaymentContract = React.useMemo(() => {
    if (!signer) return null;
    const contractInfo = getPrimaryIndexTokenAtomicRepaymentZksync(chainId);

    const smc = new Contract(contractInfo.address, contractInfo.abi, signer);

    return smc;
  }, [chainId, signer]);

  return { atomicRepaymentContract };
};
