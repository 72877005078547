const OpenGraph = ({
  locale,
  url,
  siteName,
  title,
  type,
  description,
  author,
  section,
  image,
  modifiedTime,
  publishedTime,
  card,
}) => (
  <>
    <meta property="og:locale" content={locale || "en_US"} />
    <meta property="og:title" content={title} />
    <meta property="og:type" content={type} />
    <meta property="og:description" content={description || ""} />
    <meta property="og:url" content={url} />
    <meta property="og:site_name" content={siteName} />
    {type === "article" && (
      <>
        <meta property="article:author" content={author} />
        <meta property="article:section" content={section} />
        <meta property="article:modified_time" content={modifiedTime} />
        <meta property="article:published_time" content={publishedTime} />
      </>
    )}
    {image && (
      <>
        <meta property="og:image" content={image} />
        <meta name="twitter:image" content={image} />
      </>
    )}
    <meta name="twitter:card" content={card} />
    <meta name="twitter:url" content={url} />
    <meta name="twitter:domain" content="codersteps.com" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description || ""} />
    <meta name="twitter:site" content="@codersteps" />
    <meta name="twitter:creator" content="@abdessamadely" />
  </>
);

export { OpenGraph };
