import { formatUnits } from "utils/number";
import { get } from "lodash";

export const handleGetDataFromPosition = (positionData, idx) =>
  [...positionData].reduce((res, curr) => {
    const value = formatUnits(get(curr, ["returnValues", idx], "0"), 0);
    const keyAddress = get(curr, ["methodParameters", 1], "0");
    const lendingToken = get(curr, ["methodParameters", 2], "0");
    if (value === "0") return { ...res };

    return { ...res, [keyAddress]: { value, lendingToken } };
  }, {});
