import {
  REACT_APP_MULTICALL,
  REACT_APP_SPECIAL_TOKEN_SYMBOLS,
  REACT_APP_WRAP_NATIVE_TOKEN,
} from "constants/NetworkChainId";
import { Contract, BigNumber, ethers } from "ethers";
import ethIcon from "assets/icons/eth.png";
import { NATIVE_TOKEN } from "app/constants";
import coinData from "assets/coin-list.json";
import _ from "lodash";
import { logoBySymbol } from "constants/TokenListGoerliNetwork";
import { ERC4626TokenMethod, LPTokenMethod } from "constants/methodName";
import { ContractName } from "constants/contractName";
import { Pair } from "./dex/enum/pairType";
import { TokenType } from "./dex/enum/tokenType";
import { ERC4626ABI, MultiCallZkSyncABI, UniswapV2PairABI } from "./ethereum/abi";
import { callMultiCallRequests, generateMulticallRequest } from "./contract/multicall";
import { toHex } from "./number";
import COIN_INFO from "../assets/coins_info.json";
import { defaultNetwork, isEqualLowerString } from "./network";

export const getTokenType = (underlying = []) => {
  switch (underlying.length) {
    case 0:
      return TokenType.ERC20;
    case 1:
      return TokenType.ERC4626;
    case 2:
      return TokenType.LP;
    default:
      return TokenType.ERC20;
  }
};

export const getTokenInfo = (address, underlying = [], isZkSync = false) => {
  const tokenType = getTokenType(underlying);
  let pairType;
  if (tokenType === TokenType.LP) {
    pairType = isZkSync ? Pair.Mute : Pair.Uniswap;
  }
  return {
    address,
    tokenType,
    pairType,
  };
};

export const getTokenTuple = (tokenInfo) => {
  const { tokenType, address } = tokenInfo;
  let index = 0;
  switch (tokenType) {
    case TokenType.ERC20:
      index = 0;
      break;
    case TokenType.ERC4626:
      index = 1;
      break;
    case TokenType.LP:
      index = 2;
      break;
    default:
      index = 0;
  }
  return [address, index];
};

export const getTokenUnderlying = async (address, provider) => {
  const network = await provider.getNetwork();
  const multiCallAddress = REACT_APP_MULTICALL[toHex(network.chainId)];
  const multiCallSMC = new Contract(multiCallAddress, MultiCallZkSyncABI, provider);

  // check LP token
  {
    const LPContract = new Contract(address, UniswapV2PairABI);
    const listRequest = [
      generateMulticallRequest(
        LPContract,
        LPTokenMethod.token0,
        [],
        LPContract.address,
        ContractName.LPToken
      ),
      generateMulticallRequest(
        LPContract,
        LPTokenMethod.token1,
        [],
        LPContract.address,
        ContractName.LPToken
      ),
    ];
    try {
      const results = await callMultiCallRequests(listRequest, multiCallSMC, (error) => {
        throw error;
      });
      const addresses = results.LPToken.map((result) => result.returnValues[0]);
      return addresses;
    } catch (error) {
      console.debug("Token is not LP");
    }
  }

  // check ERC4626 token
  {
    const ERC4626Contract = new Contract(address, ERC4626ABI);
    const listRequest = [
      generateMulticallRequest(
        ERC4626Contract,
        ERC4626TokenMethod.asset,
        [],
        ERC4626Contract.address,
        ContractName.ERC4626Token
      ),
    ];
    try {
      const results = await callMultiCallRequests(listRequest, multiCallSMC, (error) => {
        throw error;
      });
      const addresses = results.ERC4626Token.map((result) => result.returnValues[0]);
      return addresses;
    } catch (error) {
      console.debug("Token is not ERC-4626");
    }
  }
  return [];
};

export const wrapNativeToken = (chainId) => {
  const defaultNetworkId = `0x${defaultNetwork.id.toString(16)}`;
  const address = REACT_APP_WRAP_NATIVE_TOKEN[chainId || defaultNetworkId];
  if (!address) return {};
  const { logo, name, symbol } = NATIVE_TOKEN[chainId || defaultNetworkId] || {};
  return {
    address,
    name,
    symbol,
    logo: logo || ethIcon,
    underlyingTokens: [],
  };
};

export const isWrapNative = (address, chainId) => {
  const { address: nativeAddress } = wrapNativeToken(chainId);
  return address.toLowerCase() === nativeAddress?.toLowerCase();
};

export function getLogoBySymbolAndName(symbol = "", name = "", address = "", chainId = "") {
  const isNative = isWrapNative(address, `0x${Number(chainId).toString(16)}`);

  // check logo by symbol
  const token = isNative
    ? wrapNativeToken(`0x${Number(chainId).toString(16)}`)
    : _.get(coinData, [Number(chainId), address]);

  if (token) {
    return token.logo;
  }

  if (name && symbol) {
    const symbolList = COIN_INFO.filter((t) => t.symbol === symbol);

    if (symbolList.length === 1) return symbolList[0].logo;

    if (symbolList.length > 1) {
      const tokenFind = symbolList.filter((t) => {
        // eslint-disable-next-line camelcase
        const { contract_address } = t;
        return contract_address.find((contract) =>
          isEqualLowerString(contract.contract_address, address)
        );
      });

      if (tokenFind.length >= 1) return tokenFind[0].logo;
    }
  }
  // const logo = logoURLs["0x1"].replace("<address>", ethers.utils.getAddress(address));
  return logoBySymbol[symbol];
}

export function getSpecialTokenSymbol(address = "", networkId, defaultValue = null) {
  if (!REACT_APP_SPECIAL_TOKEN_SYMBOLS) return defaultValue;

  const chainId = `0x${Number(
    Number.isNaN(networkId) ? new BigNumber(networkId).toNumber : networkId
  ).toString(16)}`;

  if (!REACT_APP_SPECIAL_TOKEN_SYMBOLS[chainId]) return defaultValue;
  return REACT_APP_SPECIAL_TOKEN_SYMBOLS[chainId][address.toLowerCase()];
}

const getTokenSymbolByUnderlying = (address, underlyingTokens, symbol, chainId) => {
  const nativeToken = wrapNativeToken(`0x${Number(chainId).toString(16)}`);
  if (underlyingTokens?.length === 2) {
    const isNative0 = isWrapNative(
      underlyingTokens[0].address,
      `0x${Number(chainId).toString(16)}`
    );
    const isNative1 = isWrapNative(
      underlyingTokens[1].address,
      `0x${Number(chainId).toString(16)}`
    );
    const symbol0 = isNative0 ? nativeToken.symbol : underlyingTokens[0].symbol;
    const symbol1 = isNative1 ? nativeToken.symbol : underlyingTokens[1].symbol;
    return `${symbol0}/${symbol1}`;
  }
  return getSpecialTokenSymbol(address, chainId) || symbol;
};

export const mappingTokenInfo = (token, chainId) => {
  if (!token) {
    return token;
  }
  const isNative = isWrapNative(token.address, ethers.utils.hexValue(chainId));
  if (isNative) {
    const { name, symbol, logo } = wrapNativeToken(ethers.utils.hexValue(chainId));
    return {
      ...token,
      name,
      symbol,
      logo,
    };
  }
  const underlyingTokens = token.underlyingTokens?.map((x) => mappingTokenInfo(x, chainId));
  const name = getTokenSymbolByUnderlying(token.address, underlyingTokens, token.name, chainId);
  const symbol = getTokenSymbolByUnderlying(token.address, underlyingTokens, token.symbol, chainId);

  return {
    ...token,
    name,
    symbol: getSpecialTokenSymbol(token.address, chainId) || symbol,
    underlyingTokens,
    logo:
      getLogoBySymbolAndName(symbol, name, token?.address, chainId) ||
      "https://s2.coinmarketcap.com/static/img/coins/64x64/3267.png",
  };
};
