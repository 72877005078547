import BigNumber from "bignumber.js";

import { ethers } from "ethers";
import { numberToString } from "./number";

export default function truncateText(text) {
  return `${text?.substring(0, 6)}...${text?.substring(36, 43)}`;
}

export const convertNumberHex = (value) => {
  const nbToString = numberToString(value);

  return new BigNumber(value ? nbToString : Number(value)).toString();
};

export const calculateSafetyBuffer = (healthFactor) => {
  if (healthFactor) {
    if (healthFactor === 0) return 0;
    return 1 / healthFactor > 1 ? 0 : 1 - 1 / healthFactor;
  }
  return 1;
};

export function whiteBgForLogo(symbol = "") {
  if (symbol === "BAL" || symbol === "waBAL") return true;

  return false;
}

function increaseGasLimit(gas) {
  // increase 10%
  return ethers.BigNumber.from(gas).mul(110).div(100);
}

export async function getGasLimit(smc, functionName, ...args) {
  try {
    const estimated = await smc.estimateGas[functionName](...args);
    return increaseGasLimit(estimated);
  } catch (error) {
    return undefined;
  }
}
