import useWallet from "hooks/useWallet";
import { useCallback, useMemo } from "react";
import { ZERO_ADDRESS } from "utils/network";
import { getPrimaryIndexTokenZksync } from "utils/ethereum/contracts";
import {
  getDataToUpdatePrice,
  getTokensNeedToUpdatePrice,
} from "utils/ethereum/getDataToUpdatePrice";
import { Contract } from "ethers";
import { getGasLimit } from "utils/utils";
import { usePriceContract } from "./core/usePriceContract";

export const usePITContractWrite = () => {
  const { chainId, signer } = useWallet();
  const PitInstance = useMemo(() => getPrimaryIndexTokenZksync(chainId), [chainId]);

  const { PriceContract } = usePriceContract();

  const contract = useMemo(() => {
    const smc = new Contract(PitInstance.address, PitInstance.abi, signer);
    return smc;
  }, [PitInstance, signer]);

  const depositCall = useCallback(
    async ({ collateralAddress, collateralAmount }) => {
      if (!contract) return;
      const gasLimit = await getGasLimit(contract, "deposit", collateralAddress, collateralAmount);
      const callTx = await contract.deposit(collateralAddress, collateralAmount, { gasLimit });

      await callTx.wait();
    },
    [contract]
  );

  const withdrawCall = useCallback(
    async ({ collateralAddress, collateralAmount, lendingAddress }) => {
      if (!contract || !PriceContract) return;

      const tokenToGetUpdatePrice = await getTokensNeedToUpdatePrice(
        collateralAddress,
        lendingAddress,
        false,
        PriceContract
      );

      const { priceIds, payableAmount, updateData } = await getDataToUpdatePrice(
        tokenToGetUpdatePrice,
        PriceContract
      );
      const gasLimit = await getGasLimit(
        contract,
        "withdraw",
        collateralAddress,
        collateralAmount,
        priceIds,
        updateData,
        { value: payableAmount }
      );
      const callTx = await contract.withdraw(
        collateralAddress,
        collateralAmount,
        priceIds,
        updateData,
        {
          value: payableAmount,
          gasLimit,
        }
      );

      await callTx.wait();
    },
    [contract, PriceContract]
  );

  const supplyCall = useCallback(
    async ({ lendingTokenAddress, lendingTokenAmount }) => {
      if (!contract || !PriceContract) return;

      const tokenAddressNeedToUpdatePrice = await getTokensNeedToUpdatePrice(
        lendingTokenAddress,
        ZERO_ADDRESS,
        false,
        PriceContract
      );
      const { priceIds, payableAmount, updateData } = await getDataToUpdatePrice(
        tokenAddressNeedToUpdatePrice,
        PriceContract
      );
      const gasLimit = await getGasLimit(
        contract,
        "supply",
        lendingTokenAddress,
        lendingTokenAmount,
        priceIds,
        updateData,
        { value: payableAmount }
      );
      const callTx = await contract.supply(
        lendingTokenAddress,
        lendingTokenAmount,
        priceIds,
        updateData,
        {
          value: payableAmount,
          gasLimit,
        }
      );

      await callTx.wait();
    },
    [PriceContract, contract]
  );

  const redeemUnderlyingCall = useCallback(
    async ({ lendingTokenAddress, lendingTokenAmount }) => {
      if (!contract || !PriceContract) return;

      const tokenAddressNeedToUpdatePrice = await getTokensNeedToUpdatePrice(
        lendingTokenAddress,
        ZERO_ADDRESS,
        false,
        PriceContract
      );

      const { priceIds, payableAmount, updateData } = await getDataToUpdatePrice(
        tokenAddressNeedToUpdatePrice,
        PriceContract
      );
      const gasLimit = await getGasLimit(
        contract,
        "redeemUnderlying",
        lendingTokenAddress,
        lendingTokenAmount,
        priceIds,
        updateData,
        { value: payableAmount }
      );
      const callTx = await contract.redeemUnderlying(
        lendingTokenAddress,
        lendingTokenAmount,
        priceIds,
        updateData,
        { value: payableAmount, gasLimit }
      );

      await callTx.wait();
    },
    [PriceContract, contract]
  );

  const borrowCall = useCallback(
    async ({ collateral, lendingToken, lendingAmount }) => {
      if (!contract || !PriceContract) return;

      const tokenAddressNeedToUpdatePrice = await getTokensNeedToUpdatePrice(
        collateral,
        lendingToken,
        true,
        PriceContract
      );
      const { priceIds, payableAmount, updateData } = await getDataToUpdatePrice(
        tokenAddressNeedToUpdatePrice,
        PriceContract
      );
      const gasLimit = await getGasLimit(
        contract,
        "borrow",
        collateral,
        lendingToken,
        lendingAmount,
        priceIds,
        updateData,
        { value: payableAmount }
      );
      const callTx = await contract.borrow(
        collateral,
        lendingToken,
        lendingAmount,
        priceIds,
        updateData,
        {
          value: payableAmount,
          gasLimit,
        }
      );

      await callTx.wait();
    },
    [PriceContract, contract]
  );

  const repayCall = useCallback(
    async ({ collateral, lending, collateralAmount }) => {
      if (!contract) {
        return;
      }
      const gasLimit = await getGasLimit(contract, "repay", collateral, lending, collateralAmount);
      const tx = await contract.repay(collateral, lending, collateralAmount, { gasLimit });

      await tx.wait();
    },
    [contract]
  );

  return {
    callback: {
      depositCall,
      withdrawCall,
      supplyCall,
      redeemUnderlyingCall,
      borrowCall,
      repayCall,
    },
    data: {
      PitInstance,
      PitContract: contract,
    },
  };
};

export const usePITContractRead = () => {
  const { chainId, provider } = useWallet();
  const PitInstance = useMemo(() => getPrimaryIndexTokenZksync(chainId), [chainId]);

  const contract = useMemo(() => {
    const smc = new Contract(PitInstance.address, PitInstance.abi, provider);
    return smc;
  }, [PitInstance, provider]);

  return {
    data: {
      PitInstance,
      PitContract: contract,
    },
  };
};
