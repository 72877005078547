import { BigNumber } from "ethers";
import { getPriceFeedsUpdateData } from "pages/BorrowerNewDashboard/hooks/PITContract/handleGetExpiredPriceFeed";
import { getTokenUnderlying } from "utils/token";

export const getDataToUpdatePrice = async (tokenAddressList = [], PriceContract) => {
  if (!PriceContract) {
    return {
      priceIds: [],
      payableAmount: BigNumber.from(0),
      updateData: [],
    };
  }

  const { priceIds, updateFee } = await PriceContract.getExpiredPriceFeeds(
    tokenAddressList,
    BigNumber.from(15)
  );

  let updateData = [];
  if (priceIds.length > 0) {
    updateData = await getPriceFeedsUpdateData(priceIds);
  }

  return {
    priceIds,
    payableAmount: updateFee,
    updateData,
  };
};

export const getTokensNeedToUpdatePrice = async (
  projectToken,
  lendingToken,
  isBorrow,
  PriceContract
) => {
  if (!PriceContract) {
    return [];
  }
  const [projectTokenUnderlying, lendingTokenUnderlying, addresses] = await Promise.all([
    getTokenUnderlying(projectToken, PriceContract.provider),
    getTokenUnderlying(lendingToken, PriceContract.provider),
    PriceContract.getTokensUpdateFinalPrices(projectToken, lendingToken, isBorrow),
  ]);
  return Array.from(new Set([...addresses, ...projectTokenUnderlying, ...lendingTokenUnderlying]));
};
