import { Contract } from "ethers";
import { parseUnits } from "utils/number";
import {
  getInstantLeverageZkSyncContract,
  getPriceProviderAggregatorZksyncContract,
} from "./contracts";
import { getDataToUpdatePrice, getTokensNeedToUpdatePrice } from "./getDataToUpdatePrice";

export const getLendingTokenCount = async ({
  collateralTokenAddress,
  lendingTokenAddress,
  notionalValue,
  chainId,
  provider,
}) => {
  const contractInfo = getInstantLeverageZkSyncContract(chainId);

  const leverageContract = new Contract(contractInfo.address, contractInfo.abi, provider);

  const notionalValueBN = parseUnits(notionalValue.toString(), 6).toString();

  const getPriceContract = () => {
    if (!provider) return null;

    const priceContractInfo = getPriceProviderAggregatorZksyncContract(chainId);

    const smc = new Contract(priceContractInfo.address, priceContractInfo.abi, provider);
    return smc;
  };

  const PriceContract = getPriceContract();

  const tokenAddressNeedToUpdatePrice = await getTokensNeedToUpdatePrice(
    collateralTokenAddress,
    lendingTokenAddress,
    true,
    PriceContract
  );
  const { priceIds, payableAmount, updateData } = await getDataToUpdatePrice(
    tokenAddressNeedToUpdatePrice,
    PriceContract
  );

  const lendingTokenAmount =
    await leverageContract.callStatic.calculateLendingTokenCountWithUpdatePrices(
      lendingTokenAddress,
      notionalValueBN,
      priceIds,
      updateData,
      { value: payableAmount }
    );

  return lendingTokenAmount;
};
