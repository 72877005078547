import { BigNumber } from "ethers";
import { useJumpRateModelContract } from "hooks/contract/core/useJumpRateModelContract";
import useWallet from "hooks/useWallet";
import { useQuery } from "react-query";

export function useGetBlocksPer() {
  const { account, chainId } = useWallet();
  const { JumpRateModelContract } = useJumpRateModelContract();

  const result = useQuery(["blocks-per-year", account, chainId], async () => {
    if (!JumpRateModelContract) return null;

    const rs = await JumpRateModelContract.blocksPerYear();

    return rs;
  });

  if (!result) {
    return {
      blocksPerYear: 0,
      blocksPerMinute: 0,
    };
  }

  const blocksPerYear = BigNumber.from(result.data || 0);
  const blocksPerMinute = blocksPerYear.div(365).div(24).div(60);
  const blocksPerDay = blocksPerYear.div(365);

  return {
    blocksPerYear,
    blocksPerMinute,
    blocksPerDay,
  };
}
