import { Contract, constants, ethers } from "ethers";
import { MainNetworkSupported, isEqualLowerString, isZksyncNetwork } from "utils/network";
import { REACT_APP_ACCOUNT_HAVING_ETH } from "constants/NetworkChainId";
import {
  getPriceProviderAggregatorZksyncContract,
  getPrimaryIndexTokenZksync,
  getUniswapV2Factory,
} from "./contracts";
import UniSwapV2PairARTIFACT from "./json/UniswapV2Pair.json";
import { getDataToUpdatePrice } from "./getDataToUpdatePrice";
import { MutePairABI } from "./abi";

// TODO: get exposure limit from paraswap API
const getExposureLimitParaswap = async (tokenCollateral, lendingToken, chaindId) => {
  console.log("[DEBUG] - getLeverageExposureLimitParaswap", {
    tokenCollateral,
    lendingToken,
    chaindId,
  });
  return null;
};

const getExposureLimitOnTestnet = async (tokenCollateral, lendingToken, { chainId, provider }) => {
  const isZkSync = isZksyncNetwork(chainId);
  const uniswapInfo = getUniswapV2Factory(chainId);
  const PitInstance = getPrimaryIndexTokenZksync(chainId);

  const uniswapContract = new Contract(uniswapInfo?.address, uniswapInfo?.abi, provider);
  const pitContract = new Contract(PitInstance.address, PitInstance.abi, provider);

  const pair = await uniswapContract.getPair(tokenCollateral, lendingToken);

  if (isEqualLowerString(pair, constants.AddressZero)) {
    return null;
  }

  const pairContract = new ethers.Contract(
    pair,
    isZkSync ? MutePairABI : UniSwapV2PairARTIFACT,
    provider
  );
  const [token0, reserves] = await Promise.all([pairContract.token0(), pairContract.getReserves()]);

  const reserve =
    token0.toLowerCase() === lendingToken.toLowerCase() ? reserves?.[0] : reserves?.[1];

  const PriceContract = () => {
    if (!provider) return null;

    const priceContractInfo = getPriceProviderAggregatorZksyncContract(chainId);

    const smc = new Contract(priceContractInfo.address, priceContractInfo.abi, provider);
    return smc;
  };
  const { priceIds, payableAmount, updateData } = await getDataToUpdatePrice(
    [lendingToken],
    PriceContract()
  );
  const { capitalEvaluation: exposureLimit } =
    await pitContract.callStatic.getTokenEvaluationWithUpdatePrices(
      lendingToken,
      reserve,
      priceIds,
      updateData,
      { value: payableAmount, from: REACT_APP_ACCOUNT_HAVING_ETH[chainId] }
    );

  return exposureLimit;
};

export const getExposureLimit = (tokenCollateral, lendingToken, { chainId, provider }) => {
  if (MainNetworkSupported.includes(+chainId)) {
    return getExposureLimitParaswap(tokenCollateral, lendingToken, chainId);
  }

  return getExposureLimitOnTestnet(tokenCollateral, lendingToken, { chainId, provider });
};
