import { Contract } from "ethers";
import useWallet from "hooks/useWallet";
import React from "react";
import { getJumpRateModel } from "utils/ethereum/contracts";

export const useJumpRateModelContract = () => {
  const { chainId, provider } = useWallet();

  const JumpRateModelContract = React.useMemo(() => {
    if (!provider) return null;

    const contractInfo = getJumpRateModel(chainId);

    if (!contractInfo.address) return null;
    const smc = new Contract(contractInfo.address, contractInfo.abi, provider);
    return smc;
  }, [chainId, provider]);

  return { JumpRateModelContract };
};
