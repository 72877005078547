/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";

import { Grid, LinearProgress, Typography, Chip } from "@material-ui/core";

import { NumericText } from "components";

import { getHealthColor, normaliseHealthValue } from "helpers/styles";

import { ReactComponent as Rectangle } from "assets/svg/rectangle.svg";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  healthFactorWrapper: {
    position: "relative",
  },
  progress: {
    background: "linear-gradient(90deg, #FF1D1D 0%, #FFC000 50.52%, #05EE76 100%)",
    height: 6,
    margin: "2px 0",
  },
  barColorPrimary: {
    background: "transparent",
  },
  rectanglePosition: ({ progressValue }) => {
    let leftValue = progressValue;

    if (progressValue > 100) {
      leftValue = 100;
    } else if (progressValue < 0) {
      leftValue = 0;
    }

    return {
      position: "absolute",
      bottom: -7,
      left: `${leftValue}%`,
      marginLeft: progressValue > 50 ? -10 : 0,
      marginRight: progressValue < 50 ? -10 : 0,
    };
  },
  success: {
    color: "#66DD49",
    fontWeight: 500,
    fontSize: 14,
  },
  error: {
    color: theme.palette.secondary.main,
    fontWeight: 500,
    fontSize: 14,
  },
  healthValue: {
    fontWeight: 600,
    fontSize: 14,
  },
  factorValue: {
    color: theme.palette.warning.main,
  },
  chip: ({ safetyBufferValue }) => ({
    height: 22,
    backgroundColor: getHealthColor(safetyBufferValue),
    padding: 0,
    borderRadius: 6,
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.common.white,
  }),

  chipLabel: {
    paddingLeft: 10,
    paddingRight: 10,
  },
}));

const HealthFactorProgressBar = ({ value, size, isSafetyBuffer = false }) => {
  const safetyBuffer = isSafetyBuffer
    ? (Number(value) * 100).toFixed(2)
    : Number(((value - 1) * 100).toFixed(2));

  const healthFactor = isSafetyBuffer ? 1 / (1 - value) : value;

  const healthFactorProgressValue = normaliseHealthValue(healthFactor);

  const classes = useStyles({
    progressValue: safetyBuffer,
    safetyBufferValue: safetyBuffer,
  });

  if (size === "small") {
    return (
      <Chip
        label={<NumericText value={safetyBuffer} precision={2} suffix="%" />}
        className={classes.chip}
        classes={{ label: classes.chipLabel }}
      />
    );
  }

  return (
    <div className={classes.healthFactorWrapper}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography className={classes.error}>Riskier</Typography>
        </Grid>
        <Grid item>
          <Typography color="primary" className={classes.healthValue}>
            Safety Buffer: <NumericText value={safetyBuffer} precision={2} suffix="%" />
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.success}>Safer</Typography>
        </Grid>
      </Grid>

      <LinearProgress
        value={50}
        className={classes.progress}
        classes={{ barColorPrimary: classes.barColorPrimary }}
      />
      <div className={classes.rectanglePosition}>
        <Rectangle />
      </div>
    </div>
  );
};

HealthFactorProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  size: PropTypes.oneOf(["normal", "small"]),
};

HealthFactorProgressBar.defaultProps = {
  size: "normal",
};

export default HealthFactorProgressBar;
