import { DECIMAL_ETHER } from "app/constants";
import { BigNumber, Contract, constants } from "ethers";
import _get from "lodash/get";
import _reduce from "lodash/reduce";
import { useQuery } from "react-query";
import { generateMulticallRequest } from "utils/contract/multicall";
import { bUSDCContractABI } from "utils/ethereum/abi";
import { FTokenMethod } from "constants/methodName";
import { useGetBlocksPer } from "./common/useGetBlocksPer";
import { useMultiCallContract } from "./contract/multicall/useMultiCallContract";
import useWallet from "./useWallet";

const getApy = (rate, blockPerYear) => {
  // eslint-disable-next-line no-restricted-properties
  const rateIntMath = Math.pow(+rate / 10 ** DECIMAL_ETHER + 1, blockPerYear) - 1;

  return rateIntMath.toString();
};

const useGetApyOnToken = (fTokens = []) => {
  const { chainId } = useWallet();
  const { blocksPerYear } = useGetBlocksPer();

  const { callRequest } = useMultiCallContract();

  return useQuery(
    ["apy-pit-query", chainId, fTokens.map((o) => o.ftoken).toString()],
    async () => {
      const requests = [];

      fTokens.forEach((o) => {
        const FTokenContract = new Contract(o.ftoken, bUSDCContractABI);
        const reference = o.token;
        const contractName = o.ftoken;
        requests.push(
          generateMulticallRequest(
            FTokenContract,
            FTokenMethod.borrowRatePerBlock,
            [],
            reference,
            contractName
          ),
          generateMulticallRequest(
            FTokenContract,
            FTokenMethod.supplyRatePerBlock,
            [],
            reference,
            contractName
          )
        );
      });

      const results = await callRequest(requests);

      const fTokenApyList = _reduce(
        results,
        (result, value, key) => {
          const borrowRate = _get(value, [1, "returnValues", 0], BigNumber.from(0));

          const lenderRate = _get(value, [0, "returnValues", 0], BigNumber.from(0));

          const token = _get(value, [0, "reference"], constants.AddressZero);

          const data = {
            lenderApy: getApy(+borrowRate, +blocksPerYear),
            borrowApy: getApy(+lenderRate, +blocksPerYear),
            borrowRate: +borrowRate,
            lenderRate: +lenderRate,
            ftoken: key,
          };
          return { ...result, [token]: data };
        },
        {}
      );

      return fTokenApyList;
    },
    {
      retry: 3,
      enabled: Boolean(fTokens.length),
    }
  );
};

export default useGetApyOnToken;
