import { BigNumber } from "ethers";
import { formatUnits } from "utils/number";
import { groupBy, get, omit, map } from "lodash";
import { ContractName } from "constants/contractName";
import { ERC20TokenMethod, PITContractMethod } from "constants/methodName";

export const getLvrFromResult = (results, prjTokenAddress) => {
  const projectTokenInfo = groupBy(
    get(
      groupBy(get(results, [ContractName.PITContract]), "methodName"),
      PITContractMethod.projectTokenInfo
    ),
    "reference"
  );

  const lvrNumerator = get(projectTokenInfo, [prjTokenAddress, 0, "returnValues", 3, 0], 0);
  const lvrDenominator = get(projectTokenInfo, [prjTokenAddress, 0, "returnValues", 3, 1], 1);

  return lvrNumerator / (lvrDenominator || 1);
};

export const handleGetPriceInUsd = (results, key) => {
  const priceResults = groupBy(get(results, [ContractName.PriceContract]), "reference");

  const priceCollateralTokenBN = get(priceResults, [key, 0, "returnValues", 2], 1);
  const priceLendingTokenBN = get(priceResults, [key, 0, "returnValues", 3], 1);
  const priceDecimal = +get(priceResults, [key, 0, "returnValues", 0], 0);
  return {
    priceCollateralTokenBN,
    priceLendingTokenBN,
    priceDecimal,
  };
};

export const handleGetDepositTokens = ({ results, listToken, isMainNet = false }) => {
  const list = [];
  let uniswapV2Factory;

  if (isMainNet) {
    uniswapV2Factory = get(results, ContractName.UniswapV2FactoryContract);
  } else {
    uniswapV2Factory = get(results, [ContractName.UniswapV2FactoryContract]);
  }

  map(
    omit(results, [
      ContractName.PriceContract,
      ContractName.PITContract,
      ContractName.UniswapV2FactoryContract,
    ]),
    (value, key) => {
      const tokenInfo = listToken.find((o) => o.address === key);
      if (!tokenInfo) return;

      const group = groupBy(value, (x) => x.reference);

      const pairToken = isMainNet
        ? {}
        : uniswapV2Factory
            .filter((i) => get(i, ["methodParameters", 0]) === key)
            .reduce((prev, cur) => {
              const lendingToken = get(cur, ["methodParameters", 1]);
              const pairValue = get(cur, ["returnValues", 0]);

              return { ...prev, [lendingToken]: pairValue };
            }, {});

      const decimal = +get(group, [ERC20TokenMethod.decimals, 0, "returnValues", 0], "0");

      const allowance = +get(group, [ERC20TokenMethod.allowance, 0, "returnValues", 0], "0");

      const balanceBN = get(group, [ERC20TokenMethod.balanceOf, 0, "returnValues", 0], "0");
      const balanceOfWallet = formatUnits(balanceBN, decimal);

      const { priceCollateralTokenBN, priceDecimal } = handleGetPriceInUsd(results, key);

      const balanceInUsd = formatUnits(
        BigNumber.from(balanceBN).mul(priceCollateralTokenBN),
        priceDecimal + decimal
      );

      const lvr = getLvrFromResult(results, key);

      list.push({
        ...tokenInfo,
        balance: balanceOfWallet,
        allowance,
        healthFactor: 5.999994000006,
        lvr,
        price: formatUnits(priceCollateralTokenBN, priceDecimal),
        balanceInUsd,
        pairToken,
      });
    }
  );

  return list;
};
