import { BorrowContext } from "context/contracts/BorrowContext";
import useGetApyOnToken from "hooks/useApyForPIT";
import { get } from "lodash";
import { useAvailableMultiCall } from "pages/BorrowerNewDashboard/hooks";
import usePrimaryIndexTokenMultiCall from "pages/BorrowerNewDashboard/hooks/PITContract/usePrimaryIndexTokenMultiCall";
import { useAllowanceToken } from "pages/BorrowerNewDashboard/hooks/useAllowanceToken";
import { useGetTokens } from "pages/BorrowerNewDashboard/hooks/useTokenSupported";
import { useMemo } from "react";

export const BorrowContextProvider = ({ children }) => {
  const {
    loading: tokenLoading,
    projectTokenList: collaterals,
    availableBorrowTokens,
  } = useGetTokens();
  const {
    data: availableDepositData,
    isLoading: depositingLoading,
    isError: depositingError,
    isFetched: isFetched1,
    isFetching: isFetching1,
    isPlaceholderData,
  } = useAvailableMultiCall({ loading: tokenLoading, collaterals, availableBorrowTokens });

  const {
    data: borrowedData,
    isError: borrowedError,
    isLoading: borrowedLoading,
    isFetched: isFetched2,
    isFetching: isFetching2,
    refetch,
  } = usePrimaryIndexTokenMultiCall();

  const contractValue = useMemo(
    () => ({
      ...availableDepositData,
      ...borrowedData,
      isLoading: depositingLoading || borrowedLoading || tokenLoading,
      isError: borrowedError || depositingError,
      isPlaceholderData,
      placeholderDataLoading: tokenLoading,
    }),
    [
      availableDepositData,
      borrowedData,
      borrowedError,
      borrowedLoading,
      depositingError,
      depositingLoading,
      isPlaceholderData,
      tokenLoading,
    ]
  );

  const fTokens = useMemo(() => get(contractValue, "fTokenAddresses", []), [contractValue]);

  const {
    data: fTokenRate,
    isFetched: isFetched3,
    isFetching: isFetching3,
  } = useGetApyOnToken(fTokens);

  const {
    data: FTokenData,
    isFetched: isFetched4,
    isFetching: isFetching4,
  } = useAllowanceToken(fTokens);

  const contextValue = useMemo(
    () => ({
      ...contractValue,
      isFetched: isFetched1 && isFetched2 && isFetched3 && isFetched4,
      isFetching: isFetching1 || isFetching2 || isFetching3 || isFetching4,
      fTokenRate,
      allowanceForFToken: get(FTokenData, "allowanceForFToken"),
      cashFToken: get(FTokenData, "cashFToken"),
      refetch,
    }),
    [
      refetch,
      contractValue,
      isFetched1,
      isFetched2,
      isFetched3,
      isFetched4,
      isFetching1,
      isFetching2,
      isFetching3,
      isFetching4,
      fTokenRate,
      FTokenData,
    ]
  );

  return <BorrowContext.Provider value={{ ...contextValue }}>{children}</BorrowContext.Provider>;
};
