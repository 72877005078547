import { gql, useQuery as useClientQuery } from "@apollo/client";
import { Contract, ethers } from "ethers";
import { get, groupBy } from "lodash";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { useMultiCallContract } from "hooks/contract/multicall/useMultiCallContract";
import { callMultiCallRequests, generateMulticallRequest } from "utils/contract/multicall";
import {
  getPrimaryIndexTokenZksync,
  getPriceProviderAggregatorZksyncContract,
} from "utils/ethereum/contracts";
import { useProjectTokensQuery } from "hooks/contexts/ProjectTokenContext/ProjectTokenContext";
import { defaultProvider, defaultNetwork } from "utils/network";
import { ERC20TokenABI, FTokenABI } from "utils/ethereum/abi";
import { formatUnits, toHex } from "utils/number";
import { getDataToUpdatePrice } from "utils/ethereum/getDataToUpdatePrice";
import { useWallet } from "hooks";
import { mappingTokenInfo, getLogoBySymbolAndName } from "utils/token";
import {
  ERC20TokenMethod,
  FTokenMethod,
  PITContractMethod,
  PriceContractMethod,
} from "constants/methodName";
import { ContractName } from "constants/contractName";
import { getLvrFromResult } from "./helper/getDataContract";

export const useGetTokens = () => {
  const { chainId } = useWallet();

  const { data, refetch, loading } = useProjectTokensQuery();

  return {
    ...data,
    loading,
    projectTokenList: get(data, ["projectTokens"], []).map((o) => ({
      ...mappingTokenInfo(o, chainId),
      type: "projectToken",
    })),
    updateDataGraph: refetch,
    borrowLogs: groupBy(get(data, ["borrowLogs"]), "prjTokenAddress"),
    availableBorrowTokens: get(data, ["lendingTokens"], []).map((o) => ({
      ...mappingTokenInfo(o, chainId),
      type: "lendingToken",
    })),
  };
};

export const GET_PRJ_TOKEN_WITHOUT_ACCOUNT = gql`
  query ExampleQuery {
    projectTokens {
      name
      symbol
      address
      underlyingTokens {
        id
        name
        symbol
        address
        linksNumber
      }
    }
    lendingTokens {
      name
      symbol
      address
      underlyingTokens {
        id
        name
        symbol
        address
        linksNumber
      }
    }
  }
`;

function getTokenInfoRequestMulticall(PITToken, projectTokens, lendingTokens) {
  if (!PITToken.address) return null;

  const listRequest = [];

  const PITContract = new Contract(PITToken.address, PITToken.abi);
  projectTokens.forEach((token) => {
    listRequest.push(
      generateMulticallRequest(
        PITContract,
        PITContractMethod.projectTokenInfo,
        [token.address],
        token.address,
        ContractName.PITContract
      )
    );
  });

  lendingTokens.forEach((token) => {
    listRequest.push(
      generateMulticallRequest(
        PITContract,
        PITContractMethod.lendingTokenInfo,
        [token.address],
        token.address,
        ContractName.PITContract
      )
    );
  });

  return listRequest;
}

function getCashRequestMulticall(lendingTokens) {
  const listRequestToTokenContracts = [];
  lendingTokens.forEach((token) => {
    const FTokenContract = new Contract(token.fToken, FTokenABI);
    const TokenContract = new Contract(token.address, ERC20TokenABI);
    listRequestToTokenContracts.push(
      generateMulticallRequest(
        FTokenContract,
        FTokenMethod.getCash,
        [],
        token.fToken,
        token.fToken
      ),
      generateMulticallRequest(
        TokenContract,
        ERC20TokenMethod.decimals,
        [],
        token.address,
        token.fToken
      )
    );
  });
  return listRequestToTokenContracts;
}

async function getCash(lendingTokens, callRequest) {
  const requests = getCashRequestMulticall(lendingTokens);
  const cashResults = await callRequest(requests);
  let cashFToken = {};
  Object.keys(cashResults).forEach((fTokenAddress) => {
    const current = cashResults[fTokenAddress];
    const cashValue = get(current, ["0", "returnValues", 0], "0");
    const decimal = get(current, ["1", "returnValues", 0], "0");
    const lendingToken = get(current, ["1", "reference"], "");

    cashFToken = {
      ...cashFToken,
      [lendingToken]: {
        cash: formatUnits(cashValue, decimal),
      },
    };
  });
  return cashFToken;
}

function decodeTokenInfoFromMulticallResponse(result, projectTokenList, lendingTokenList, chainId) {
  const PITContract = get(result, [ContractName.PITContract], []);
  const projectTokens = PITContract.filter(
    (params) => params.methodName === PITContractMethod.projectTokenInfo
  ).map((token) => {
    const prjTokenAddress = get(token, ["methodParameters", 0], "");
    const tokenInfo = projectTokenList.find((o) => o.address === prjTokenAddress);
    const lvr = getLvrFromResult(result, prjTokenAddress);

    return {
      ...tokenInfo,
      lvr,
      logo: getLogoBySymbolAndName(tokenInfo.symbol, tokenInfo.name, tokenInfo?.address, chainId),
    };
  });

  const lendingTokens = PITContract.filter(
    (params) => params.methodName === PITContractMethod.lendingTokenInfo
  ).map((token) => {
    const lendingTokenAddress = get(token, ["methodParameters", 0], "");
    const fToken = get(token, ["returnValues", 2], "");
    const tokenInfo = lendingTokenList.find((o) => o.address === lendingTokenAddress);
    const lvr = getLvrFromResult(result, lendingTokenAddress);

    return {
      ...tokenInfo,
      fToken,
      lvr,
      logo: getLogoBySymbolAndName(tokenInfo.symbol, tokenInfo.name, tokenInfo?.address, chainId),
    };
  });

  return { projectTokens, lendingTokens };
}

export async function getPrice(
  formattedLendingTokens = [],
  PriceContractInfo,
  multiCallSMC,
  provider
) {
  const PriceContract = new Contract(PriceContractInfo.address, PriceContractInfo.abi, provider);
  const requests = [];
  const tokensNeedToUpdatePrice = [];
  formattedLendingTokens.forEach((token) => {
    if (token.underlyingTokens?.length > 0) {
      token.underlyingTokens.forEach((underlyingToken) => {
        tokensNeedToUpdatePrice.push(underlyingToken.address);
      });
    } else {
      tokensNeedToUpdatePrice.push(token.address);
    }
  });
  const { priceIds, payableAmount, updateData } = await getDataToUpdatePrice(
    Array.from(new Set(tokensNeedToUpdatePrice)),
    PriceContract
  );

  for (let i = 0; i < formattedLendingTokens.length; i += 1) {
    const token = formattedLendingTokens[i];

    requests.push(
      generateMulticallRequest(
        PriceContract,
        PriceContractMethod.getUpdatedPriceMethod,
        [token.address, priceIds, updateData],
        token.address,
        ContractName.PriceContract,
        payableAmount
      )
    );
  }

  const priceResults = await callMultiCallRequests(requests, multiCallSMC);
  const prices = {};
  priceResults?.[ContractName.PriceContract]?.forEach((o) => {
    const priceLendingTokenBN = get(o, ["returnValues", 3], 1);
    const priceDecimal = +get(o, ["returnValues", 0], 0);
    const lendingToken = get(o, ["reference"], "");
    prices[lendingToken] = formatUnits(priceLendingTokenBN, priceDecimal);
  });
  return prices;
}

export const useGetTokensWithoutAccount = () => {
  const { data } = useClientQuery(GET_PRJ_TOKEN_WITHOUT_ACCOUNT);
  const { account } = useWallet();

  const projectTokens = useMemo(() => get(data, "projectTokens", []), [data]);
  const lendingTokens = useMemo(() => get(data, "lendingTokens", []), [data]);

  const { callRequest } = useMultiCallContract();

  const keys = Array.from(new Set([...projectTokens, ...lendingTokens].map((s) => s.address)))
    .sort()
    .join(",");
  // const eth = new Eth(metamaskProvider);
  const listTokenWithoutAccount = useQuery(
    ["get-token-without-account", keys],
    async () => {
      const chainId = ethers.utils.hexValue(defaultNetwork.id);

      const PITToken = getPrimaryIndexTokenZksync(chainId);

      const requests = getTokenInfoRequestMulticall(PITToken, projectTokens, lendingTokens);

      const results = await callRequest(requests);

      const { projectTokens: formattedProjectTokens, lendingTokens: formattedLendingTokens } =
        decodeTokenInfoFromMulticallResponse(results, projectTokens, lendingTokens, chainId);

      const cashFToken = await getCash(formattedLendingTokens, callRequest);
      return {
        projectTokens: formattedProjectTokens.map((token) => ({
          ...mappingTokenInfo(token, toHex(defaultNetwork.id)),
          allowance: false,
          balance: "0.0",
          healthFactor: 0,
          price: 0,
          balanceInUsd: 0,
          isLeverage: false,
        })),
        lendingTokens: formattedLendingTokens.map((token) => ({
          ...mappingTokenInfo(token, toHex(defaultNetwork.id)),
          cash: cashFToken[token.address]?.cash,
          balanceOf: "0.0",
          price: 0,
          balanceInUsd: "0",
          balanceOfUnderlyingView: "0",
          apy: "0",
        })),
      };
    },
    {
      retry: 3,
      enabled: !account && !!lendingTokens?.length && !!projectTokens?.length,
    }
  );

  return {
    data: listTokenWithoutAccount.data,
    error: listTokenWithoutAccount.isError,
    isLoading: listTokenWithoutAccount.isLoading,
  };
};

export const usePriceTokensWithoutAccount = (tokens) => {
  const { multiCallSMC } = useMultiCallContract();
  const { account } = useWallet();
  const keys = tokens
    ? Array.from(tokens.map((s) => s.address))
        .sort()
        .join(",")
    : "";
  // const eth = new Eth(metamaskProvider);
  const getPriceTokens = useQuery(
    ["get-price-tokens", keys],
    async () => {
      const chainId = ethers.utils.hexValue(defaultNetwork.id);

      const PriceContract = getPriceProviderAggregatorZksyncContract(chainId);

      const prices = await getPrice(tokens, PriceContract, multiCallSMC, defaultProvider);

      return prices;
    },
    {
      retry: 3,
      enabled: !account && tokens?.length > 0,
    }
  );

  return {
    data: getPriceTokens.data,
    error: getPriceTokens.isError,
    isLoading: getPriceTokens.isLoading,
  };
};
