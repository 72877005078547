import BigNumber from "bignumber.js";
import { MIN_AMPLIFY } from "constants/contract";
import { useDebounce } from "hooks/common/useDebounce";
import { createContext, useEffect, useMemo, useState } from "react";
import { useGetLVR } from "hooks/pit/useGetLVR";
import { LeverageContextProvider } from "../LeverageContext/LeverageContext";
import { useLeverageContext } from "../LeverageContext/useLeverageContext";

export const AmplifyContext = createContext();

const ContextProvider = ({ children }) => {
  const {
    margin: [, setLeverageMargin],
    amplification: [, setLeverageAmplification],
    setNotionalExp,
    setSafeBuffer,
    longAssetSelected,
    shortAssetSelected,
    resetStates: resetLeverageContextStates,
  } = useLeverageContext();

  const [margin, setMargin] = useState("");
  const [amplification, setAmplification] = useState(MIN_AMPLIFY);

  const getLvr = useGetLVR(longAssetSelected?.address, shortAssetSelected?.address);
  const lvr = useMemo(() => (getLvr ? getLvr[0] / getLvr[1] : 0), [getLvr]);

  const debouncedAmplify = useDebounce(amplification, 1000);
  const debouncedMargin = useDebounce(margin, 1000);

  const notionalExp = useMemo(
    () =>
      new BigNumber(debouncedAmplify).minus(1).multipliedBy(debouncedMargin).toFixed(6).toString(),
    [debouncedAmplify, debouncedMargin]
  );

  const resetStates = () => {
    setMargin("");
    setAmplification(MIN_AMPLIFY);
    resetLeverageContextStates();
  };

  useEffect(() => {
    if (notionalExp && lvr && debouncedMargin) {
      const marginBN = new BigNumber(debouncedMargin);
      const lvrBN = new BigNumber(lvr);
      const notionalExpBN = new BigNumber(notionalExp);

      const safeBufferCal = new BigNumber(1).minus(
        notionalExpBN.dividedBy(lvrBN.multipliedBy(marginBN.plus(notionalExpBN)))
      );

      setSafeBuffer(safeBufferCal);
    } else {
      setSafeBuffer("");
    }
  }, [lvr, debouncedMargin, notionalExp, setSafeBuffer]);

  useEffect(() => {
    setNotionalExp(notionalExp);
  }, [notionalExp, setNotionalExp]);

  useEffect(() => {
    setLeverageMargin(debouncedMargin);
  }, [debouncedMargin, setLeverageMargin]);

  useEffect(() => {
    setLeverageAmplification(amplification);
  }, [amplification, setLeverageAmplification]);

  const contextValue = {
    margin,
    setMargin,
    setAmplification,
    resetStates,
  };

  return <AmplifyContext.Provider value={contextValue}>{children}</AmplifyContext.Provider>;
};

export const AmplifyContextProvider = ({ children }) => (
  <LeverageContextProvider>
    <ContextProvider>{children}</ContextProvider>
  </LeverageContextProvider>
);
