import {
  BUSDC_CONTRACT,
  LENDING_TOKEN_ADDRESS,
  PRICE_PROVIDER_AGGREGATOR_CONTRACT,
  PRIMARY_INDEX_TOKEN,
  REACT_APP_UNISWAPV2_FACTORY_ETHEREUM,
  REACT_APP_UNISWAPV2_ROUTER,
  ATOMIC_REPAYMENT_CONTRACT,
  REACT_APP_INSTANT_LEVERAGE,
  JUMP_RATE_MODEL,
  REACT_APP_MUTE_ROUTER,
  REACT_APP_MUTE_FACTORY,
  REACT_APP_PYTH_PRICE_PROVIDER,
  PRIMARY_LENDING_WRAPPED_TOKEN_GATEWAY,
} from "constants/NetworkChainId";
import { ZERO_ADDRESS } from "utils/network";
import {
  bUSDCContractABI,
  UniswapV2FactoryAbi,
  UniswapV2RouterAbi,
  PriceProviderAggregatorZksyncABI,
  PrimaryIndexTokenZksyncABI,
  AtomicRepaymentZksyncAbi,
  InstantLeverageZkSyncAbi,
  JumpRateModelABI,
  MuteRouterABI,
  MuteFactoryABI,
  PrimaryLendingWrappedTokenGatewayABI,
} from "./abi";

export const getPrimaryIndexTokenZksync = (networkId) => ({
  address: PRIMARY_INDEX_TOKEN[networkId] || ZERO_ADDRESS,
  abi: PrimaryIndexTokenZksyncABI,
});

export const bUSDCContract = (networkId) => ({
  address: BUSDC_CONTRACT[networkId],
  abi: bUSDCContractABI,
});

export const getPriceProviderAggregatorZksyncContract = (networkId) => ({
  address: PRICE_PROVIDER_AGGREGATOR_CONTRACT[networkId] || ZERO_ADDRESS,
  abi: PriceProviderAggregatorZksyncABI,
});

export const getPrimaryIndexTokenAtomicRepaymentZksync = (networkId) => ({
  address: ATOMIC_REPAYMENT_CONTRACT[networkId] || ZERO_ADDRESS,
  abi: AtomicRepaymentZksyncAbi,
});

export const getUniswapV2Factory = (networkId) => ({
  address: REACT_APP_UNISWAPV2_FACTORY_ETHEREUM[networkId] || ZERO_ADDRESS,
  abi: UniswapV2FactoryAbi,
});

export const getUniswapV2Router = (networkId) => ({
  address: REACT_APP_UNISWAPV2_ROUTER[networkId] || ZERO_ADDRESS,
  abi: UniswapV2RouterAbi,
});

export const getInstantLeverageZkSyncContract = (networkId) => ({
  address: REACT_APP_INSTANT_LEVERAGE[networkId] || ZERO_ADDRESS,
  abi: InstantLeverageZkSyncAbi,
});

export const lendingTokenAddress = (networkId) => LENDING_TOKEN_ADDRESS[networkId || 5];

export const getJumpRateModel = (networkId) => ({
  address: JUMP_RATE_MODEL[networkId] || ZERO_ADDRESS,
  abi: JumpRateModelABI,
});

export const getMuteFactory = (networkId) => ({
  address: REACT_APP_MUTE_FACTORY[networkId],
  abi: MuteFactoryABI,
});

export const getMuteRouter = (networkId) => ({
  address: REACT_APP_MUTE_ROUTER[networkId],
  abi: MuteRouterABI,
});

export const getPythProvider = (networkId) => ({
  address: REACT_APP_PYTH_PRICE_PROVIDER[networkId] || ZERO_ADDRESS,
});

export const getPrimaryLendingPlatformWrappedTokenGateway = (networkId) => ({
  address: PRIMARY_LENDING_WRAPPED_TOKEN_GATEWAY[networkId] || ZERO_ADDRESS,
  abi: PrimaryLendingWrappedTokenGatewayABI,
});
