import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink as Link } from "react-router-dom";
import pythIcon from "assets/icons/pyth.png";
import chainlinkIcon from "assets/icons/chainlink.png";

import { ReactComponent as FooterIcon } from "assets/svg/footer-icon.svg";
import { useBlockNumber } from "hooks/query";

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: "25px 30px",
    marginTop: 50,
    backgroundColor: theme.palette.primary.main,
    borderTop: `1px solid ${theme.palette.primary.dark}`,
    [theme.breakpoints.down("md")]: {
      padding: "25px 15px",
    },
  },

  footerContainer: {
    padding: "0 30px",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      gap: 24,
    },

    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },

  link: {
    color: "#797C7D",
    fontSize: 14,
    transition: theme.transitions.main,
    textDecoration: "none",

    "&:hover": {
      color: theme.palette.primary.dark,
      transition: theme.transitions.main,
    },
  },

  icon: {
    marginRight: 9,
  },

  footerText: {
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
    },
  },

  footerCopyright: {
    color: theme.palette.common.white,
    fontSize: 14,

    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  latestBlock: {
    fontSize: 12,
    fontWeight: 500,
  },
  textCenterMobile: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  priceFeedsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 6,
    "& a": {
      lineHeight: 0,
    },
  },
  priceFeedsLabel: {
    color: "#797C7D",
    fontSize: 14,
    transition: theme.transitions.main,
    textDecoration: "none",
  },
  priceFeedsLogo: {
    height: 30,
  },
}));

const Footer = () => {
  const classes = useStyles();
  const { data } = useBlockNumber();
  const blockNumber = data || "-";

  return (
    <footer className={classes.footer}>
      <Container>
        <Grid container className={classes.footerContainer} justifyContent="space-between">
          <Grid item container spacing={3} alignItems="center" md={8}>
            <Grid item xs={12} sm={3} md="auto">
              <Typography align="center" className={classes.footerCopyright}>
                {new Date().getFullYear()} © Fringe
              </Typography>
            </Grid>

            <Grid item xs={6} sm={3} md="auto" className={classes.textCenterMobile}>
              <Link to="/terms-of-service" className={classes.link}>
                Terms of Service
              </Link>
            </Grid>

            <Grid item xs={6} sm={3} md="auto" className={classes.textCenterMobile}>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSfoI1e1Qh7ti7aiQ-LdZHyWIAwQWA9I8OnNNyex5GvegEbjUA/viewform"
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                Feedback
              </a>
            </Grid>
            <Grid item xs={12} sm={3} md="auto" className={classes.priceFeedsContainer}>
              <span className={classes.priceFeedsLabel}>Price feeds by</span>
              <a href="https://pyth.network" target="_blank" rel="noreferrer">
                <img
                  className={classes.priceFeedsLogo}
                  src={pythIcon}
                  alt="Pyth Brand"
                  rel="noreferrer"
                />
              </a>
              <a href="https://chain.link" target="_blank" rel="noreferrer">
                <img
                  className={classes.priceFeedsLogo}
                  src={chainlinkIcon}
                  alt="Chainlink Brand"
                  rel="noreferrer"
                />
              </a>
            </Grid>
          </Grid>

          <Grid item md={4} container alignItems="center" className={classes.footerText}>
            <FooterIcon className={classes.icon} />
            <Typography color="secondary" className={classes.latestBlock}>
              Latest block: {blockNumber}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
