import PropTypes from "prop-types";
import ReactGa from "react-ga";

import { get } from "lodash";
import { NavLink as Link } from "react-router-dom";
import { useConnect } from "wagmi";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";

// import WalletLoader from "assets/gif/walletLoader.gif";
// import { ReactComponent as LeftArrow } from "assets/svg/arrow-left.svg";
import { ReactComponent as RightArrow } from "assets/svg/arrow-right.svg";
import { ReactComponent as CoinbaseWallet } from "assets/svg/coinbase.svg";
import { ReactComponent as LogoIcon } from "assets/svg/logo.svg";
import { ReactComponent as MetamaskLogo } from "assets/svg/MetamaskIcon.svg";
import { ReactComponent as Walletconnect } from "assets/svg/walletconnect.svg";
import { ReactComponent as BrowserWallet } from "assets/svg/browser-wallet.svg";
import { ReactComponent as RabbyIcon } from "assets/svg/rabby-icon.svg";
import { ReactComponent as BraveIcon } from "assets/svg/brave-icon.svg";
import { ReactComponent as LedgerIcon } from "assets/svg/ledger-icon.svg";
import { ReactComponent as TrustWalletIcon } from "assets/svg/trustwallet-icon.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      padding: "4px 8px",
    },
  },

  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  link: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
    transition: theme.transitions.main,
    "&:hover": {
      color: theme.palette.secondary.dark,
      transition: theme.transitions.main,
    },
  },

  buttonWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 15,
    marginBottom: 15,
    [theme.breakpoints.down("sm")]: {
      marginTop: 15,
      marginBottom: 15,
    },
  },

  button: {
    display: "flex",
    width: "100%",
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.info.main}`,
    borderRadius: 5,
    paddingLeft: 0,
    paddingRight: 0,
    transition: theme.transitions.main,
    "&:hover": {
      backgroundColor: "transparent",
      border: `1px solid ${theme.palette.secondary.main}`,
      transition: theme.transitions.main,
    },
    "&:hover $walletName": {
      color: theme.palette.secondary.main,
      transition: theme.transitions.main,
    },
  },

  walletName: {
    marginLeft: 15,
    color: theme.palette.primary.light,
    fontSize: 24,
    transition: theme.transitions.main,
  },

  connectText: {
    marginTop: 20,
  },

  buttonInner: {
    display: "flex",
    alignItems: "center",
    width: "80%",
  },

  metamaskIcon: {
    width: 54,
    height: 54,
    [theme.breakpoints.down("sm")]: {
      width: 32,
      height: 32,
    },
  },
  rightIcon: {
    width: 32,
    height: 32,
  },

  loader: {
    display: "block",
    width: "100px",
    height: "100px",
    margin: "40px auto 30px auto",
    objectFit: "contain",
  },

  backArrow: {
    position: "absolute",
    top: "20px",
    left: "20px",
    cursor: "pointer",
  },

  text: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
}));

const ConnectWalletDialog = ({ onClose }) => {
  const classes = useStyles();

  const { connectors, connectAsync } = useConnect();
  const { enqueueSnackbar } = useSnackbar();

  const InjectedWalletTable = {
    isBraveWallet: { name: "Brave Wallet", icon: <BraveIcon className={classes.metamaskIcon} /> },
    isRabby: { name: "Rabby Wallet", icon: <RabbyIcon className={classes.metamaskIcon} /> },
    isTrust: { name: "Trust Wallet", icon: <TrustWalletIcon className={classes.metamaskIcon} /> },
    isLedgerConnect: {
      name: "Ledger Wallet",
      icon: <LedgerIcon className={classes.metamaskIcon} />,
    },
  };

  const getInjection = () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, wallet] of Object.entries(InjectedWalletTable)) {
      if (window.ethereum?.[key]) return wallet;
    }
    if (window.ethereum?.isMetaMask || window.ethereum?.isCoinbaseWallet) return null;

    if (window.ethereum) {
      return { name: "Browser Wallet", icon: <BrowserWallet className={classes.metamaskIcon} /> };
    }
    return null;
  };

  const handleConnectWallet = async (connector) => {
    try {
      connectAsync({ connector }).catch((error) => {
        if (error.message === "Connector not found") {
          enqueueSnackbar("Wallet not found", {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      });

      ReactGa.event({
        category: "Wallet",
        action: "Connect Wallet",
      });

      onClose();
    } catch {
      // setWalletConnecting(false);
    }
  };

  const injected = getInjection();

  return (
    <Box className={classes.root}>
      <Box className={classes.box}>
        <Box my={2}>
          <LogoIcon />
        </Box>

        <Box>
          <Typography variant="h4" align="center">
            Connect wallet
          </Typography>

          <Box mt={1}>
            <Typography variant="body1" align="center" color="primary">
              To start using Fringe
            </Typography>
          </Box>
        </Box>

        <Box className={classes.buttonWrapper}>
          <Button
            onClick={() => handleConnectWallet(get(connectors, 0))}
            className={classes.button}
          >
            <Box className={classes.buttonInner}>
              <MetamaskLogo className={classes.metamaskIcon} />
              <Typography className={classes.walletName}>Metamask</Typography>
            </Box>
            <RightArrow className={classes.rightIcon} />
          </Button>
        </Box>

        <Box className={classes.buttonWrapper}>
          <Button
            onClick={() => handleConnectWallet(get(connectors, 1))}
            className={classes.button}
          >
            <Box className={classes.buttonInner}>
              <Walletconnect className={classes.metamaskIcon} />
              <Typography className={classes.walletName}>Walletconnect</Typography>
            </Box>
            <RightArrow className={classes.rightIcon} />
          </Button>
        </Box>

        <Box className={classes.buttonWrapper}>
          <Button
            onClick={() => handleConnectWallet(get(connectors, 2))}
            className={classes.button}
          >
            <Box className={classes.buttonInner}>
              <CoinbaseWallet className={classes.metamaskIcon} />
              <Typography className={classes.walletName}>Coinbase</Typography>
            </Box>
            <RightArrow className={classes.rightIcon} />
          </Button>
        </Box>
        {injected ? (
          <Box className={classes.buttonWrapper}>
            <Button
              onClick={() => handleConnectWallet(get(connectors, 3))}
              className={classes.button}
            >
              <Box className={classes.buttonInner}>
                {injected.icon}
                <Typography className={classes.walletName}>{injected.name}</Typography>
              </Box>
              <RightArrow className={classes.rightIcon} />
            </Button>
          </Box>
        ) : null}
      </Box>

      <Box my={2} className={classes.text}>
        <Typography>
          By connecting, I accept{" "}
          <Link to="/terms-of-service" className={classes.link} onClick={onClose}>
            Fringe&#39;s Terms of Service
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

ConnectWalletDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ConnectWalletDialog;
