import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { LinearProgress, Typography } from "@material-ui/core";

import { NumericText } from "components";

import { getHealthColor } from "helpers/styles";

import { ReactComponent as Pointer } from "assets/svg/mapPointer.svg";

const useStyles = makeStyles((theme) => ({
  healthFactorWrapper: {
    position: "relative",
  },
  progress: {
    background: "linear-gradient(90deg, #FF1D1D 0%, #FFC000 50.52%, #05EE76 100%)",
    height: 6,
    margin: "2px 0",
  },
  barColorPrimary: {
    background: "transparent",
  },
  healthFactor: ({ progressValue }) => ({
    color: getHealthColor(progressValue),
  }),
  rectanglePosition: ({ progressValue }) => {
    let leftValue = progressValue;

    if (progressValue > 100) {
      leftValue = 100;
    } else if (progressValue < 0) {
      leftValue = 0;
    }

    return {
      position: "absolute",
      bottom: -2,
      left: `${leftValue}%`,
      marginLeft: progressValue > 50 ? -10 : 0,
      marginRight: progressValue < 50 ? -10 : 0,
    };
  },
  healthValue: {
    marginBottom: 10,
    fontWeight: 500,
    fontSize: 14,
    color: theme.palette.common.white,
  },
  liquidatePrice: {
    marginTop: -10,
    marginBottom: 10,
    fontWeight: 500,
    fontSize: 14,
    color: theme.palette.common.white,
  },
  description: {
    marginBottom: 10,
    fontWeight: 300,
    fontSize: 12,
    color: theme.palette.common.white,
    fontStyle: "italic",
  },
}));

const HealthTooltip = ({ value, price, collateralSymbol, lendingSymbol }) => {
  const safetyBuffer = ((1 - 1 / value) * 100).toFixed(2);
  const safetyBufferCheck = Number(((value - 1) * 100).toFixed(2));
  const liquidatePrice = safetyBufferCheck < 0 ? Number(price) * value : 0;

  const classes = useStyles({
    progressValue: safetyBuffer,
  });
  return (
    <div className={classes.healthFactorWrapper}>
      <Typography className={classes.description}>
        Maximum collateral price decline <br />
        before position is subject to liquidation
      </Typography>
      <Typography className={classes.healthValue}>
        Safety Buffer:{" "}
        <span className={classes.healthFactor}>
          <NumericText value={safetyBuffer} precision={2} suffix="%" />
        </span>
      </Typography>
      {value < 1 && (
        <Typography className={classes.liquidatePrice}>
          Liquidation price:{" "}
          <span className={classes.healthFactor}>
            <NumericText
              value={liquidatePrice}
              precision={2}
              suffix={`${collateralSymbol}/${lendingSymbol}`}
            />
          </span>
        </Typography>
      )}

      <LinearProgress
        value={50}
        className={classes.progress}
        classes={{ barColorPrimary: classes.barColorPrimary }}
      />
      <div className={classes.rectanglePosition}>
        <Pointer width={13} height={13} />
      </div>
    </div>
  );
};

HealthTooltip.propTypes = {
  value: PropTypes.number.isRequired,
};

export default HealthTooltip;
