import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";

import React from "react";
import { NumericText } from "components/NumericText";

const useStyles = makeStyles({
  dialogTotal: {
    width: "100%",
    backgroundColor: "#333333",
  },
});

const DialogTotal = ({ title, value, currency, children, type = 1 }) => {
  const classes = useStyles();
  return (
    <Box px={3} py={type} className={classes.dialogTotal}>
      {children || (
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography color="secondary">{title}</Typography>
          </Grid>
          <Grid item>
            <Typography color="secondary">
              <NumericText value={value} suffix={currency} precision={5} />
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

DialogTotal.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency: PropTypes.string,
  children: PropTypes.node,
};

DialogTotal.defaultProps = {
  title: "",
  value: 0,
  currency: "",
  children: null,
};

export default DialogTotal;
