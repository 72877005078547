import { providers } from "ethers";
import {
  arbitrumGoerli,
  goerli,
  optimismGoerli,
  polygonMumbai,
  mainnet,
  polygon,
  arbitrum,
  optimism,
  zkSyncTestnet,
  sepolia,
} from "wagmi/chains";

// Customize AlchemyProvider to supported Base chain
providers.AlchemyProvider.getUrl = (network, apiKey) => {
  let host = null;
  switch (network.name) {
    case "homestead":
      host = "eth-mainnet.alchemyapi.io/v2/";
      break;
    case "goerli":
      host = "eth-goerli.g.alchemy.com/v2/";
      break;
    case "matic":
      host = "polygon-mainnet.g.alchemy.com/v2/";
      break;
    case "maticmum":
      host = "polygon-mumbai.g.alchemy.com/v2/";
      break;
    case "arbitrum":
      host = "arb-mainnet.g.alchemy.com/v2/";
      break;
    case "arbitrum-goerli":
      host = "arb-goerli.g.alchemy.com/v2/";
      break;
    case "optimism":
      host = "opt-mainnet.g.alchemy.com/v2/";
      break;
    case "optimism-goerli":
      host = "opt-goerli.g.alchemy.com/v2/";
      break;
    case "base-sepolia":
      host = "base-sepolia.g.alchemy.com/v2/";
      break;
    case "base":
      host = "base-mainnet.g.alchemy.com/v2/";
      break;
    case "zksync-era":
      host = "zksync-mainnet.g.alchemy.com/v2/";
      break;
    default:
      throw new Error("unsupported network", "network", network);
  }

  return {
    allowGzip: true,
    url: `https://${host}${apiKey}`,
    throttleCallback: () => Promise.resolve(true),
  };
};

providers.InfuraProvider.getUrl = (network, apiKey) => {
  let host = null;
  switch (network ? network.name : "unknown") {
    case "homestead":
      host = "mainnet.infura.io";
      break;
    case "goerli":
      host = "goerli.infura.io";
      break;
    case "sepolia":
      host = "sepolia.infura.io";
      break;
    case "matic":
      host = "polygon-mainnet.infura.io";
      break;
    case "maticmum":
      host = "polygon-mumbai.infura.io";
      break;
    case "optimism":
      host = "optimism-mainnet.infura.io";
      break;
    case "optimism-goerli":
      host = "optimism-goerli.infura.io";
      break;
    case "arbitrum":
      host = "arbitrum-mainnet.infura.io";
      break;
    case "arbitrum-goerli":
      host = "arbitrum-goerli.infura.io";
      break;
    case "base-sepolia":
      host = "base-sepolia.infura.io";
      break;
    case "base":
      host = "base-mainnet.infura.io";
      break;
    default:
      throw new Error("unsupported network", "network", network);
  }

  const connection = {
    allowGzip: true,
    url: `https://${host}/v3/${apiKey.projectId}`,
    throttleCallback: () => Promise.resolve(true),
  };

  if (apiKey.projectSecret != null) {
    connection.user = "";
    connection.password = apiKey.projectSecret;
  }

  return connection;
};

const zkSync = {
  id: 324,
  name: "zkSync Era",
  network: "zksync-era",
  nativeCurrency: {
    decimals: 18,
    name: "Ether",
    symbol: "ETH",
  },
  rpcUrls: {
    default: {
      http: ["https://mainnet.era.zksync.io"],
      webSocket: ["wss://mainnet.era.zksync.io/ws"],
    },
    public: {
      http: ["https://mainnet.era.zksync.io"],
      webSocket: ["wss://mainnet.era.zksync.io/ws"],
    },
    alchemy: {
      http: ["https://eth-mainnet.g.alchemy.com/v2"],
      webSocket: ["wss://eth-mainnet.g.alchemy.com/v2"],
    },
  },
  blockExplorers: {
    default: {
      name: "zkExplorer",
      url: "https://explorer.zksync.io",
    },
  },
  contracts: {
    multicall3: {
      address: "0x47898B2C52C957663aE9AB46922dCec150a2272c",
    },
  },
};

export {
  arbitrumGoerli,
  goerli,
  optimismGoerli,
  polygonMumbai,
  mainnet,
  polygon,
  arbitrum,
  optimism,
  zkSyncTestnet,
  zkSync,
  sepolia,
};
