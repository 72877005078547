import { constants, Contract } from "ethers";
import React, { useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { ERC20TokenABI } from "utils/ethereum/abi";
import { useSnackbar } from "notistack";
import useWallet from "hooks/useWallet";

export const useERC20TokenApproval = (tokenAddress, spender) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { signer, account } = useWallet();
  const ERC20 = React.useMemo(
    () => (tokenAddress && spender ? new Contract(tokenAddress, ERC20TokenABI, signer) : null),
    [signer, tokenAddress, spender]
  );

  const handleError = useCallback(
    (error) => {
      if (error.message === "userRejectedRequest" || error.code === "ACTION_REJECTED") {
        enqueueSnackbar("Transaction has been canceled", {
          variant: "error",
          autoHideDuration: 5000,
        });
      } else {
        enqueueSnackbar(`Transaction rejected: ${error?.reason}`, {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    },
    [enqueueSnackbar]
  );

  const query = useQuery(
    [`erc20-token${tokenAddress}`, spender],
    async () => {
      const res = await ERC20.functions.allowance(account, spender);
      return res[0];
    },
    {
      enabled: Boolean(account) && Boolean(tokenAddress) && !!spender,
    }
  );

  const mutateApproval = useMutation(
    async (data) => {
      const tx = await ERC20.approve(spender, data?.value || constants.MaxUint256);
      await tx.wait(1);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([`erc20-token${tokenAddress}`, spender]);
        await queryClient.invalidateQueries(["available-multicall", account]);
        await queryClient.invalidateQueries(["borrowed-pit-multicall", account]);
        enqueueSnackbar("Transaction approved!", {
          variant: "success",
          autoHideDuration: 5000,
        });
      },
      onError: handleError,
    }
  );

  return { query, approve: mutateApproval.mutateAsync, isLoading: mutateApproval.isLoading };
};
