import { usePITContractRead } from "hooks/contract/usePITContract";
import useWallet from "hooks/useWallet";
import { useQuery } from "react-query";

export const useGetLVR = (prjToken, lendingToken) => {
  const { account, chainId } = useWallet();
  const {
    data: { PitContract },
  } = usePITContractRead();

  const getLVR = useQuery(
    ["get-loan-to-value-ratio", account, chainId, prjToken, lendingToken],
    async () => {
      const res = await PitContract.getLoanToValueRatio(prjToken, lendingToken);

      return res;
    },
    {
      enabled: !!(prjToken && lendingToken),
    }
  );

  return getLVR.data;
};
