/* eslint-disable no-underscore-dangle */
import { ApolloClient, InMemoryCache, defaultDataIdFromObject } from "@apollo/client";
import { CHAIN_ID } from "app/constants";
import { GraphByChainId } from "constants/graphUrl";

const getUri = (chainId) => {
  if (GraphByChainId[chainId]) {
    return GraphByChainId[chainId];
  }
  if (GraphByChainId[process.env.REACT_APP_DEFAULT_CHAIN_ID]) {
    return GraphByChainId[process.env.REACT_APP_DEFAULT_CHAIN_ID];
  }
  return GraphByChainId[Object.keys(GraphByChainId)[0]];
};

export const getClient = (chainId = CHAIN_ID) =>
  new ApolloClient({
    uri: getUri(chainId),
    cache: new InMemoryCache({
      dataIdFromObject(responseObject) {
        switch (responseObject.__typename) {
          case "LendingToken":
            return `LendingToken:${responseObject.address}`;
          case "ProjectToken":
            return `ProjectToken:${responseObject.address}`;
          case "TotalState":
            return `TotalState:${responseObject.type}:${responseObject.lendingTokenAddress}`;
          default:
            return defaultDataIdFromObject(responseObject);
        }
      },
    }),
  });
