/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@material-ui/core/styles";
import { useWallet } from "hooks";
import { ListItemIcon, ListItemText } from "@mui/material";
import { ReactComponent as OpenNewTabLogo } from "assets/svg/open-new-tab.svg";
import { ReactComponent as EyeLogo } from "assets/svg/eye.svg";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  threeDot: {
    "&:after": {
      content: "'\\2807'",
      fontSize: 20,
    },
    cursor: "pointer",
  },
  popper: {
    zIndex: 1,
  },
  menuList: {
    color: "white",
    backgroundColor: "#434849",
  },
  listItemText: {
    "& span": {
      fontSize: 14,
    },
    textAlign: "start",
  },
  link: {
    color: "unset",
    "& p": {
      fontStyle: "unset",
    },
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export default function DetailMenu({ address, underlyingTokens, symbol }) {
  const { explorer } = useWallet();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const classes = useStyles();

  const history = useHistory();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  };

  const tokenExplorer = React.useMemo(() => {
    if (!address || !explorer?.default?.url) {
      return {
        url: "",
        explorerName: "",
      };
    }
    const url = `${explorer.default.url}/address/${address}`;
    const explorerName = explorer.default.name;

    return {
      url,
      explorerName,
    };
  }, [address, explorer]);

  const assetLandingLink = React.useMemo(() => {
    const assetSymbol =
      underlyingTokens?.length === 2
        ? `${underlyingTokens[0].symbol}-${underlyingTokens[1].symbol}`
        : symbol;
    if (assetSymbol) {
      return `/borrowing/${assetSymbol}`;
    }
    return "";
  }, [symbol, underlyingTokens]);

  const handleViewToken = () => {
    if (tokenExplorer.url.length) {
      window.open(tokenExplorer.url, "_blank");
    }
    handleClose({});
  };
  const handleViewDetail = () => {
    if (assetLandingLink.length) {
      history.push(assetLandingLink);
    }
    handleClose({});
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Stack direction="row" spacing={2}>
      <div>
        <div
          ref={anchorRef}
          className={classes.threeDot}
          onClick={() => {
            handleToggle();
          }}
          onKeyDown={() => {
            handleToggle();
          }}
        />
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="top-end"
          transition
          disablePortal
          className={classes.popper}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    className={classes.menuList}
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={(event) => {
                      handleListKeyDown(event);
                    }}
                  >
                    {tokenExplorer.url.length > 0 ? (
                      <MenuItem onClick={handleViewToken}>
                        <ListItemIcon>
                          <OpenNewTabLogo width={20} height={20} />
                        </ListItemIcon>
                        <ListItemText className={classes.listItemText}>
                          View token contract
                        </ListItemText>
                      </MenuItem>
                    ) : null}
                    <MenuItem onClick={handleViewDetail}>
                      <ListItemIcon>
                        <EyeLogo width={20} height={20} />
                      </ListItemIcon>
                      <ListItemText className={classes.listItemText}>Detail</ListItemText>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
}
