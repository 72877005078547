/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Box, CircularProgress } from "@material-ui/core";
import CoinStat from "./CoinStat";

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "left",
    marginLeft: "15%",
    marginRight: "15%",
    color: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  title: {
    fontSize: "26px",
    marginBottom: 20,
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  stats: {
    background: "#454545",
    border: "3px solid #5c5c5c",
    borderRadius: "2px",
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
    display: "flex",
    justifyContent: "space-between",
    gap: 40,
    flexWrap: "wrap",
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  statsLoading: {
    justifyContent: "center !important",
  },
  statsItem: {
    [theme.breakpoints.down("sm")]: {
      "& p": {
        textAlign: "center",
      },
    },
  },
  statsItemTitle: {
    fontSize: "1rem",
    color: "#a5a8b6",
  },
  statsItemUsdValue: {
    fontSize: "1.5rem",
    fontWeight: 600,
  },
  statsItemTokenValue: {
    fontSize: "0.875rem",
    color: "#a5a8b6",
  },
}));

const CoinStats = ({ price, name, symbol, coins }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={`${classes.title} loading`} variant="h2">
        {name} lending market stats
      </Typography>
      {coins &&
        coins.map((coin) => (
          <CoinStat
            key={coin?.address}
            address={coin?.address}
            name={name}
            symbol={symbol}
            price={price}
            network={coin?.network}
          />
        ))}
    </Box>
  );
};

CoinStats.propTypes = {
  name: PropTypes.string.isRequired,
  symbol: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
};

CoinStats.defaultProps = {};

export default CoinStats;
