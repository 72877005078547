import useWallet from "hooks/useWallet";
import { gql, useQuery as useClientQuery } from "@apollo/client";

export const useFetchLeverageBorrowData = () => {
  const { account } = useWallet();

  const GET_PRJ_TOKEN = gql`
    query ExampleQuery($account: String!) {
      leveragedBorrowLogs(where: { userAddress: $account }, orderBy: date, orderDirection: desc) {
        exposureAmount
        date
        exposureLendingCount
        id
        lendingToken
        lendingTokenAddress
        lendingTokenPrice
        marginAmount
        marginCount
        prjToken
        prjTokenAddress
        prjTokenPrice
        type
        userAddress
      }
    }
  `;

  const { data, refetch, loading } = useClientQuery(GET_PRJ_TOKEN, {
    variables: { account },
    // pollInterval: 500,
  });

  const laveragedBorrowList = [];

  if (data?.leveragedBorrowLogs?.length > 0) {
    data?.leveragedBorrowLogs.forEach((borrow) => {
      const existedBorrow = laveragedBorrowList.find(
        (x) => x.prjTokenAddress === borrow.prjTokenAddress
      );

      if (!existedBorrow) {
        laveragedBorrowList.push(borrow);
      }
    });
  }

  return { laveragedBorrowList, refetch, loading };
};
