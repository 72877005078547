import { PITContractMethod } from "constants/methodName";
import { usePriceContract } from "hooks/contract/core/usePriceContract";
import { usePITContractRead } from "hooks/contract/usePITContract";
import useWallet from "hooks/useWallet";
import { useQuery } from "react-query";
import {
  getDataToUpdatePrice,
  getTokensNeedToUpdatePrice,
} from "utils/ethereum/getDataToUpdatePrice";

export const useGetAvailableToWithdraw = (prjToken, lendingToken) => {
  const { account, chainId } = useWallet();
  const {
    data: { PitContract },
  } = usePITContractRead();
  const { PriceContract } = usePriceContract();

  const availableToWithdraw = useQuery(
    ["available-to-withdraw", account, chainId, prjToken, lendingToken],
    async () => {
      const tokenAddressNeedToUpdatePrice = await getTokensNeedToUpdatePrice(
        prjToken,
        lendingToken,
        false,
        PriceContract
      );
      const { priceIds, payableAmount, updateData } = await getDataToUpdatePrice(
        tokenAddressNeedToUpdatePrice,
        PriceContract
      );

      const res = await PitContract.callStatic[
        PITContractMethod.getCollateralAvailableToWithdrawWithUpdatePrices
      ](account, prjToken, lendingToken, priceIds, updateData, {
        value: payableAmount,
      });

      return res;
    },
    {
      enabled: !!PriceContract && !!PitContract,
    }
  );

  return availableToWithdraw;
};
