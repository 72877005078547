/* eslint-disable no-await-in-loop */
import { EvmPriceServiceConnection } from "@pythnetwork/pyth-evm-js";

const connection = new EvmPriceServiceConnection("https://hermes.pyth.network", { timeout: 10000 });

export async function getPriceFeedsUpdateData(priceIds) {
  const updateData = await connection.getPriceFeedsUpdateData(priceIds);

  return updateData;
}
