import { useState } from "react";

import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import MuiLink from "@material-ui/core/Link";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import clsx from "clsx";

import { ReactComponent as Logo } from "assets/svg/PLPLogo.svg";
import { ReactComponent as LogoIcon } from "assets/svg/logoIcon.svg";
import { ReactComponent as StakingLogoText } from "assets/svg/stakingLogoText.svg";
import { ReactComponent as USBLogoText } from "assets/svg/usbLogoText.svg";
import { ReactComponent as SelectIcon } from "assets/svg/arrowSelect.svg";

const useStyles = makeStyles({
  selectContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    padding: "10px 30px 10px 10px",
    cursor: "pointer",
    "&:after": {
      content: "''",
      position: "absolute",
      right: -10,
      top: "50%",
      height: "calc(100% + 33px)",
      width: 1,
      transform: "translateY(-50%)",
    },
  },
  selectList: {
    zIndex: 9,
    position: "absolute",
    top: "calc(100% + 14px)",
    left: "50%",
    width: "calc(100% + 20px)",
    padding: "20px",
    backgroundColor: "#191919",
    boxShadow: "0 17px 20px 0 rgba(0, 0, 0, 0.38)",
    opacity: 0,
    pointerEvents: "none",
    transform: "translateX(-50%)",
  },
  active: {
    opacity: 1,
    pointerEvents: "initial",
  },
  platform: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  logoIcon: {
    marginRight: 10,
  },
  logoSize: {
    width: "190px",
  },
});

const STAKING_URL = process.env.REACT_APP_STAKING_URL;
const USB_URL = process.env.REACT_APP_USB_URL;

const PlatformSelect = () => {
  const classes = useStyles();
  const [active, setActive] = useState(false);

  const isSelectAvailable = STAKING_URL || USB_URL;

  const handleChange = () => {
    setActive((prev) => !prev);
  };

  const handleClose = () => {
    setActive(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box position="relative" className={classes.selectContainer}>
        <Box display="flex">
          <Link to="/">
            <Box display="flex" flexDirection="row" alignItems="center">
              <Logo className={classes.logoSize} />
            </Box>
          </Link>

          {isSelectAvailable && (
            <IconButton onClick={handleChange}>
              <SelectIcon />
            </IconButton>
          )}
        </Box>

        {isSelectAvailable && (
          <Box className={clsx(classes.selectList, { [classes.active]: active })}>
            {STAKING_URL && (
              <MuiLink href={STAKING_URL}>
                <Box mb={2} className={classes.platform}>
                  <LogoIcon className={classes.logoIcon} />
                  <StakingLogoText />
                </Box>
              </MuiLink>
            )}
            {USB_URL && (
              <MuiLink href={USB_URL}>
                <Box className={classes.platform}>
                  <LogoIcon className={classes.logoIcon} />
                  <USBLogoText />
                </Box>
              </MuiLink>
            )}
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  );
};
export default PlatformSelect;
